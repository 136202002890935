import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";

import HousingRoomBlockForm from "./HousingRoomBlockForm";

const HousingRoomBlock = props => {
  const { editBlockId, goIndex, setEditBlockId } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [block, setBlock] = useState(false);

  useEffect(() => {
    const fetchBlock = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/housing/blocks", `/${editBlockId}`)
        );
        setBlock(result.data.block);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchBlock();
  }, [apiRoot, editBlockId]);

  const success = () => {
    goIndex();
    setEditBlockId(null);
  };

  const failure = () => {
    alertError("Failed to update block");
  };

  const renderBlockForm = () => {
    if (fetched) {
      return (
        <HousingRoomBlockForm
          callbackFailure={failure}
          callbackSuccess={success}
          cancelButton={goIndex}
          block={block}
        />
      );
    }
    return <Loading />;
  };

  return <div>{renderBlockForm()}</div>;
};

export default HousingRoomBlock;
