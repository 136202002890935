import React, { useContext } from "react";

import Modal from "react-modal";

import EventContext from "@event/EventContext";
import { renderCancelButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import { htmlStrip } from "@shared/TextUtils";
import { formatTimeUtcToZone } from "@shared/TimeUtils";

const FormSubmissionModal = (props) => {
  const { modalVisible, resetModal, formSubmission, form } = props;
  const { event } = useContext(EventContext).values;

  Modal.setAppElement("#root");

  const renderField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const getFormQuestions = () => {
    const sorted_questions = form.pages
      .filter((x) => !x.archived)
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((x) =>
        x.questions
          .filter((x) => !x.hide_on_reports)
          .filter((x) => !x.archived)
          .sort((a, b) => a.sort_order - b.sort_order)
      )
      .flat();
    return sorted_questions;
  };

  const renderFormAnswers = (question) => {
    return renderField(getQuestionLabel(question), (formSubmission.response || {})[question.attribute_id]);
  };

  const getQuestionLabel = (question) => {
    try {
      if (question.label && (question.properties || {}).use_rich_text_label) {
        return htmlStrip(question.label);
      }
      return question.label || "";
    } catch (error) {
      return "";
    }
  };

  const renderViewSubmission = () => {
    if (!formSubmission) return <Loading />;
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Form Submission</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="sg-mgmt-modal-view-section">
              <h2>Details</h2>
              {renderField("Submitted At", formatTimeUtcToZone(formSubmission.updated_at, event.time_zone))}
              {renderField(
                "Attendee",
                formSubmission.event_participant ? formSubmission.event_participant.name_full : "N/A"
              )}
            </div>
            <div className="sg-mgmt-modal-view-section">
              <h2>Submission Answers</h2>
              {getFormQuestions().map((question) => renderFormAnswers(question))}
            </div>
            <div className="sg-mgmt-modal-view-section">GID: {formSubmission.gid}</div>
            <div className="sg-mgmt-modal-view-section">{renderCancelButton("Done", resetModal)}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Speaker"
    >
      {renderViewSubmission()}
    </Modal>
  );
};

export default FormSubmissionModal;
