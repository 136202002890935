import React, { useCallback, useContext, useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router";
import urljoin from "url-join";

import MetadataFieldModal from "@configurations/custom_fields/MetadataFieldModal";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";

import FormsFormsFormPageForm from "./FormsFormsFormPageForm";
import FormsFormsFormPages from "./FormsFormsFormPages";
import FormsFormsGeneralInfoForm from "./FormsFormsGeneralInfoForm";

const FormsFormsForm = ({ formId, cancel, form_type }) => {
  const [form, setForm] = useState({});
  const [currentPage, setCurrentPage] = useState(null);
  const [pages, setPages] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [metaData, setMetaDataFields] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const navigate = useNavigate();
  const { apiRoot } = useContext(EventContext).values;
  const [editingTime, setEditingTime] = useState(false);
  const [metaDataModalVisible, setMetaDataModalVisible] = useState(false);

  const resetEditingTime = (forceValue = null) => {
    if (forceValue) {
      console.log({ forceValue });
      forceValue = new Date(forceValue.getTime() + 1000);
    }
    setEditingTime(forceValue ? forceValue + 2000 : new Date());
  };

  const isFormEditedSinceEditingTime = async () => {
    const result = await axios(urljoin(apiRoot, `/forms/${formId}/edited_since?since=${editingTime}`));
    return result.data;
  };

  const isEdit = () => {
    return formId;
  };

  const fetchMetadataFields = useCallback(async () => {
    try {
      const result = await axios(urljoin(apiRoot, "/metadata/fields"));
      setMetaDataFields(result.data);
    } catch (error) {
      alertHttpError(error);
    }
  }, [apiRoot]);

  useEffect(() => {
    const fetchTicketTypes = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/ticketing/ticket_types"));
        setTicketTypes(result.data);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchPackageTypes = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/ticketing/package_types"));
        setPackageTypes(result.data);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchForms = async () => {
      try {
        if (formId) {
          const result = formId
            ? await axios(urljoin(apiRoot, `forms/${formId}`))
            : await axios(urljoin(apiRoot, "forms/new"));
          setPages(result.data["form"]["pages"]);
          setForm(result.data["form"]);

          resetEditingTime(new Date(result.data["form"]["updated_at"]));
        } else {
          const result1 = await axios(urljoin(apiRoot, `forms/new?form_type=${form_type}`));
          navigate(`/forms/${form_type}/${result1.data["form"].id}`);

          const result = await axios(urljoin(apiRoot, `forms/${result1.data["form"].id}}`));
          setPages(result.data["form"]["pages"]);
          setForm(result.data["form"]);
          resetEditingTime(new Date(result.data["form"]["updated_at"]));
        }
      } catch (error) {
        alertHttpError(error);
      }
    };
    fetchMetadataFields();
    fetchTicketTypes();
    fetchPackageTypes();
    fetchForms();
  }, [apiRoot, fetchMetadataFields, formId, form_type, navigate]);

  const setFormPage = (page) => {
    setPages(pages.filter((x) => x.id != page.id).concat([page]));
  };

  const removeFormPage = (page_gid) => {
    setCurrentPage(null);
    setDirty(false);
    setPages(pages.filter((x) => x.gid != page_gid));
  };

  const orderPages = () => {
    axios(urljoin(apiRoot, `forms/${formId}`));
  };

  const changePage = (page) => {
    if (!dirty || confirm("You have unsaved changes. Are you sure you want to leave?")) {
      setCurrentPage(page);
      setDirty(false);
    }
  };

  const addPage = () => {
    axios({
      url: urljoin(apiRoot, `/forms/${form.id}/pages/`),
      method: "POST",
      data: {}
    })
      .then((response) => {
        if (response.data.error === null) {
          setPages([...pages, response.data.page]);
        } else {
          alertError(`Unable to save form: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const sortPages = (page_ids) => {
    axios({
      url: urljoin(apiRoot, `/forms/${form.id}/pages/sort`),
      method: "POST",
      data: {
        page_ids: page_ids,
        form_id: form.id
      }
    })
      .then((response) => {
        if (response.data.error === null) {
          let newPages = pages.map((page) => {
            const newPage = response.data.pages.find((p) => p.id === page.id);
            page.sort_order = newPage.sort_order;
            return page;
          });
          setPages(newPages);
        } else {
          alertError(`Unable to save page order: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-forms-edit",
        formUrl: urljoin(apiRoot, "/forms", `/${form.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit " + form.name
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-forms-add",
      formUrl: urljoin(apiRoot, "/forms/"),
      method: "POST",
      saveButton: "Save",
      title: "Create New Form"
    };
  })();

  const renderFormikFormsForm = () => {
    return (
      <div className="sg-mgmt-form">
        <FormsFormsFormPages
          pages={pages}
          currentPage={currentPage}
          orderPages={orderPages}
          addPage={addPage}
          changePage={changePage}
          sortPages={sortPages}
          metaData={metaData}
          ticketTypes={ticketTypes}
          packageTypes={packageTypes}
        />
        <div className="">{renderCurrentPage()}</div>
      </div>
    );
  };

  const renderCurrentPage = () => {
    if (currentPage) {
      const currentPageObject = pages.find((page) => page.id === currentPage);
      return (
        <FormsFormsFormPageForm
          form={form}
          isFormEditedSinceEditingTime={isFormEditedSinceEditingTime}
          metaData={metaData}
          ticketTypes={ticketTypes}
          packageTypes={packageTypes}
          formPage={currentPageObject}
          setFormPage={setFormPage}
          setDirty={setDirty}
          formConfig={formConfig}
          key={`forms-forms-form-page-${currentPageObject.id}`}
          cancel={cancel}
          removeFormPage={removeFormPage}
          resetEditingTime={resetEditingTime}
          openMetaDataModal={openMetaDataModal}
        />
      );
    }
    return (
      <FormsFormsGeneralInfoForm
        form={form}
        resetEditingTime={resetEditingTime}
        setForm={setForm}
        setDirty={setDirty}
        formConfig={formConfig}
        cancel={cancel}
      />
    );
  };

  const openMetaDataModal = () => {
    setMetaDataModalVisible(true);
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <h1>Edit Form</h1>
      {form && form.id && pages != null ? renderFormikFormsForm() : <Loading />}
      <MetadataFieldModal
        field={null}
        modalVisible={metaDataModalVisible}
        resetModal={() => setMetaDataModalVisible(false)}
        title="Create a new data point"
        updateFunc={() => {
          fetchMetadataFields();
          setMetaDataModalVisible(false);
        }}
        fieldType={"event_participant"}
      />
    </div>
  );
};

export default FormsFormsForm;
