import React, { useState, useContext } from "react";

import clsx from "clsx";
import { NavLink } from "react-router";

import EventUserContext from "../EventUserContext";

const EventMenuDropdown = (props) => {
  const { links = [], onOpen = () => {}, icon = "tile", title = "Tile Tile" } = props;
  const { user } = useContext(EventUserContext);
  const [open, setOpen] = useState(false);
  const iconUrl = `/images/nav/${icon}.svg`;
  const linkStyle = { textDecoration: "none" };

  const hasRequiredAccess = () => {
    if (user.role !== "basic") {
      return true;
    }
    const permittedLinks = links.filter((x) => hasLinkRequiredAccess(x));
    return permittedLinks.length > 0;
  };

  const hasLinkRequiredAccess = (link) => {
    if (user.role !== "basic") {
      return true;
    }
    for (const permission of link.requiredPermissions) {
      if (!user.permission[permission]) {
        return false;
      }
    }
    return true;
  };

  const renderLinks = () => {
    return links.filter((x) => hasLinkRequiredAccess(x)).map((link) => renderLink(link));
  };

  const renderLink = (link) => {
    return (
      <NavLink
        key={link.link}
        style={linkStyle}
        to={link.link}
        className={({ isActive }) =>
          clsx("sg-mgmt-menu-nav-link", "sg-mgmt-menu-nav-inner-link", isActive ? "sg-mgmt-menu-nav-link-active" : "")
        }
      >
        <div className="sg-mgmt-menu-item flex cursor-pointer items-center">
          <div className="flex h-[50px] w-[50px] shrink-0 cursor-pointer items-center justify-center"></div>
          <div className="sg-mgmt-menu-item-title flex-grow text-left font-['Roboto'] text-base font-normal">
            {link.title}
          </div>
        </div>
      </NavLink>
    );
  };

  const toggleMenu = (value) => {
    setOpen(value);
    if (value) {
      onOpen();
    }
  };

  if (hasRequiredAccess()) {
    return (
      <div className="">
        <div className="sg-mgmt-menu-nav-link">
          <div className="sg-mgmt-menu-item flex items-center" onClick={() => toggleMenu(!open)}>
            <div className="flex h-[50px] w-[50px] shrink-0 cursor-pointer items-center justify-center">
              <img
                className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px]"
                src={iconUrl}
                alt={`${title} nav icon`}
              />
            </div>
            <div className="sg-mgmt-menu-item-title flex-grow text-left font-['Roboto'] text-base font-medium">
              {title}
            </div>
            <div className="sg-mgmt-menu-dropdown p-2">
              <img className={` ${open ? "rotate-180" : ""}`} src={"/images/icons/expand-arrow.svg"} />
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="to-slate-5 bg-gradient-to-l from-sky-50">{open ? renderLinks() : ""}</div>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default EventMenuDropdown;
