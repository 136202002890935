import React, { useContext } from "react";

import { useParams } from "react-router";

import EventContext from "@event/EventContext";
import { useMetadataFields } from "@shared/hooks/useMetadata";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import MetadataTable from "./MetadataTable";

const CustomFieldType = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { typeKey } = useParams();
  const fields = useMetadataFields(apiRoot, event.id);

  const metadataTypes = {
    participants: {
      label: "Participants",
      fieldType: "event_participant"
    },
    sessions: {
      label: "Sessions",
      fieldType: "session"
    },
    event: {
      label: "Event",
      fieldType: "event"
    }
  };

  if (fields.isPending) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  if (fields.isError) {
    return (
      <div className="w-full">
        <div className="alert alert-danger">Error loading custom fields</div>
      </div>
    );
  }

  const tableFields = fields.data.filter((f) => f.field_type === metadataTypes[typeKey]?.fieldType);

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <PageHeader text="Custom Fields" />
      <div className="my-2">
        <p>Here you can define custom fields for different record types.</p>
      </div>
      <div className="my-2">
        <MetadataTable
          key={`table-${typeKey}`}
          fields={tableFields}
          fieldType={metadataTypes[typeKey].fieldType}
          label={metadataTypes[typeKey].label}
          addField={() => {}}
          forceSetFields={() => {}}
        />
      </div>
    </div>
  );
};

export default CustomFieldType;
