import React from "react";

import RouterTabbedPage from "@shared/RouterTabbedPage";

const CustomFields = () => {
  return (
    <RouterTabbedPage
      tabs={[
        { label: "Event", path: "/custom_fields/event" },
        { label: "Participants", path: "/custom_fields/participants" },
        { label: "Sessions", path: "/custom_fields/sessions" }
      ]}
    />
  );
};

export default CustomFields;
