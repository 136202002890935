import React, { useContext, useEffect, useState } from "react";

import { Stack, Chip } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import { useNavigate } from "react-router";
import urljoin from "url-join";
import { v4 as uuidv4 } from "uuid";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import { renderSubmitButton, renderButton, renderCancelButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

const PeopleEventParticipantsImportMapping = ({ callbackSuccess, sheet, headerValues }) => {
  const navigate = useNavigate();

  const { apiRoot } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [fields, setFields] = useState({
    fetched: false,
    data: []
  });

  const [metadataFields, setMetadataFields] = useState({
    fetched: false,
    data: []
  });

  const mapRow1 = sheet.mapping_preview[0];
  const mapRow2 = sheet.mapping_preview[1];
  const mapRow3 = sheet.mapping_preview[2];

  const formConfig = {
    alert: "added",
    buttonColor: "secondary",
    cancelUrl: "/participants",
    formId: "sg-mgmt-form-list-mapping",
    formUrl: urljoin(apiRoot, `/participants/import/${sheet.id}/mapping`),
    method: "POST",
    title: "Import Participants"
  };

  useEffect(() => {
    const sortFields = (fieldsToSort) => sortBy(fieldsToSort, ["section", "sort_order"]);

    const fetchTags = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "participant_tags"));
        setTags(result.data.tags);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchFields = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/details/registration"));
        setFields({
          fetched: true,
          data: sortFields(result.data)
        });
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchMetaDataFields = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/metadata/fields"));
        setMetadataFields({
          fetched: true,
          data: sortFields(result.data)
        });
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchFields();
    fetchMetaDataFields();
    fetchTags();
  }, [apiRoot]);

  const formInitialValues = () => {
    return {
      mapping: sheet.mapping_autodetect || {}
    };
  };

  const generateKey = (_prefix) => {
    return uuidv4();
  };

  const renderFieldSelect = (idx) => {
    const fieldOptions = [
      { label: "(unused)", value: "" },
      { label: "Email", value: "email" },
      { label: "First Name", value: "name_first" },
      { label: "Last Name", value: "name_last" },
      { label: "Job Title", value: "job_title" },
      { label: "Company", value: "company" },
      { label: "Role", value: "role" },
      { label: "Status", value: "status" },
      { label: "Address (line 1)", value: "override_work_address_street_1" },
      { label: "Address (line 2)", value: "override_work_address_street_2" },
      { label: "City", value: "override_work_address_city" },
      { label: "State/Province", value: "override_work_address_state" },
      { label: "ZIP/Postal Code", value: "override_work_address_postcode" },
      { label: "Country", value: "override_work_address_country" },
      { label: "Phone", value: "override_work_phone" },
      { label: "Participant Type", value: "participant_type" },
      { label: "Opt Out", value: "opt_out" },
      { label: "Test Flag", value: "test_flag" },
      { label: "Attended", value: "attended" }
    ];

    fields.data.forEach((field) => {
      fieldOptions.push({ label: field.label, value: `custom-${field.slug}` });
    });

    metadataFields.data.forEach((field) => {
      fieldOptions.push({
        label: field.label,
        value: `metadata-${field.slug}`
      });
    });

    // increment the index value as the Excel sheets count from 1
    return <SelectField name={`import[mapping][${idx + 1}]`} options={fieldOptions} />;
  };

  const renderSelectFields = () => (
    <tr>
      {Array.from(Array(mapRow1.length)).map((_, i) => (
        <td key={`mapping-field-${i}`}>{renderFieldSelect(i)}</td>
      ))}
    </tr>
  );

  const renderHeaderRow = () => (
    <tr key={`mapping-header-row`} className="font-bold">
      {headerValues.map((val) => (
        <td key={generateKey(val)}>{val}</td>
      ))}
    </tr>
  );

  const renderPreviewRow = (idx, preview) => (
    <tr key={`mapping-field-row-${idx}`}>
      {preview.map((val) => (
        <td key={generateKey(val)}>{val}</td>
      ))}
    </tr>
  );

  const addTag = (gid) => {
    // ignore duplicate tag
    if (selectedTags.map((t) => t.gid).includes(gid)) {
      return;
    }

    // ignore missing tag
    if (!gid) {
      return;
    }

    const tag = tags.find((t) => t.gid === gid);
    setSelectedTags([tag].concat(selectedTags));
  };

  const removeTag = (gid) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t.gid !== gid));
  };

  const handleAddTagForm = (value) => {
    addTag(value);
  };

  const renderSelectedTags = () => {
    return (
      <div className="mt-2 flex">
        {selectedTags.map((tag) => (
          <Chip key={tag.id} className="mr-2" color="primary" label={tag.name} onDelete={() => removeTag(tag.gid)} />
        ))}
      </div>
    );
  };

  const renderTagAddButton = (values) => {
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return renderButton(
      "Add",
      () => {
        handleAddTagForm(tag_add);
      },
      { disabled: disabled, color: "secondary" }
    );
  };

  const renderTagSelect = (values) => {
    const filteredTags = tags.filter((t) => !selectedTags.map((tag) => tag.gid).includes(t.gid));
    const options = filteredTags.map((opt) => ({
      label: opt.name,
      value: opt.gid
    }));
    return (
      <div className="sg-mgmt-meeting-host-select-container">
        <div className="w-[300px]">
          <Stack direction="row" spacing={2}>
            <SelectField
              options={options}
              className="sg-mgmt-form-input"
              name="tag_add"
              autoComplete="off"
              blankLabel="Select tag"
              includeBlank
            />
            {renderTagAddButton(values)}
          </Stack>
        </div>
      </div>
    );
  };

  const renderTags = (values) => {
    return (
      <div>
        <div className="my-6">{"Optional: Assign tag(s) to the imported participants."}</div>
        {renderTagSelect(values)}
        <div className="pb-4">{renderSelectedTags()}</div>
      </div>
    );
  };

  const submitEnable = (isSubmitting) => {
    return isSubmitting;
  };

  const requiredMappings = (values) => {
    const requiredFields = ["name_first", "name_last", "email"];
    let allFieldsPresent = true;
    const mappedValues = Object.values(values.import.mapping);
    requiredFields.forEach((field) => {
      if (!mappedValues.includes(field)) allFieldsPresent = false;
    });
    return allFieldsPresent;
  };

  const renderParticipantImportMapping = () => {
    if (fetched && fields.fetched) {
      return (
        <Formik
          initialValues={{
            import: formInitialValues()
          }}
          onSubmit={(values, { setSubmitting }) => {
            const form = document.getElementById(formConfig.formId);
            const formData = new FormData(form);
            const token = document.querySelector("[name=csrf-token]").content;
            axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

            const mappedValues = Object.values(values.import.mapping);
            let abort = false;

            // Put tags into array
            const tagNames = selectedTags.map((t) => t.gid).join(",");
            formData.set("import[tags]", tagNames);

            // TODO: figure out this kind of non-field-specific validation with
            // a more Formik idiomatic approach
            if (!mappedValues.includes("email")) {
              alertError("You must identify a field with email addresses");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "email").length > 1) {
              alertError("You must have only one field identified as Email");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "name_first").length > 1) {
              alertError("You must have only one field identified as First Name");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "name_last").length > 1) {
              alertError("You must have only one field identified as Last Name");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "company").length > 1) {
              alertError("You must have only one field identified as Company");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "job_title").length > 1) {
              alertError("You must have only one field identified as Job Title");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "role").length > 1) {
              alertError("You must have only one field identified as Role");
              abort = true;
            }

            if (mappedValues.filter((e) => e === "status").length > 1) {
              alertError("You must have only one field identified as Status");
              abort = true;
            }

            if (abort) {
              setSubmitting(false);
              return;
            }

            axios({
              url: formConfig.formUrl,
              method: formConfig.method,
              data: formData
            })
              .then((response) => {
                callbackSuccess(response.data);
              })
              .catch((error) => {
                alertHttpError(error);
              });
          }}
        >
          {({ isSubmitting, values }) => (
            <Form id={formConfig.formId}>
              <div className="mb-4 mt-6 text-lg font-normal text-sg-orange">{"Step 2: Mapping Data"}</div>
              <div className="mb-6">
                {
                  "The dropdown menus represent existing data points. To map data, please select the data point that represents the column below. Use the horizontal scrollbar to see all columns that can be assigned."
                }
                <br />
                {
                  "Note: Only email, and first and last name are required for a successful import, but the more you map, the more complete the import will be."
                }
              </div>
              <div className="my-2 overflow-x-auto">
                {isSubmitting ? <Loading /> : <></>}
                <table className={`sg-mgmt-table sg-mgmt-table-import ${isSubmitting ? "hidden" : ""}`}>
                  <tbody>
                    {renderSelectFields()}
                    {renderHeaderRow()}
                    {renderPreviewRow(1, mapRow1)}
                    {renderPreviewRow(2, mapRow2)}
                    {renderPreviewRow(3, mapRow3)}
                  </tbody>
                </table>
              </div>
              <div className="my-2">{renderTags(values)}</div>
              <Stack direction="row" spacing={2}>
                {renderSubmitButton(formConfig.title, submitEnable(isSubmitting) || !requiredMappings(values), {
                  color: formConfig.buttonColor
                })}
                {renderCancelButton(
                  "Cancel",
                  () => {
                    navigate(formConfig.cancelUrl);
                  },
                  { color: formConfig.buttonColor }
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      );
    }
    return <Loading />;
  };

  return <div>{renderParticipantImportMapping()}</div>;
};

export default PeopleEventParticipantsImportMapping;
