import React, { useContext } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError } from "@shared/Alerts";
import { renderDateField, renderSelectField, renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

const HousingConfigurationEdit = (props) => {
  const { back, configuration, updateFunc } = props;
  const { apiRoot } = useContext(EventContext).values;

  const formConfig = (() => {
    return {
      alert: "updated",
      formId: "sg-mgmt-form-token-edit",
      formUrl: urljoin(apiRoot, "/housing/configuration"),
      method: "PATCH",
      saveButton: "Update",
      title: "Edit Field"
    };
  })();

  const formInitialValues = () => {
    return {
      date_begin: configuration.date_begin || "",
      date_end: configuration.date_end || "",
      check_in_date_default: configuration.check_in_date_default || "",
      check_out_date_default: configuration.check_out_date_default || "",
      booking_date_begin: configuration.booking_date_begin || "",
      booking_date_end: configuration.booking_date_end || "",
      booking_time_begin: configuration.booking_time_begin || "",
      booking_time_end: configuration.booking_time_end || "",
      currency: configuration.currency || "USD"
    };
  };

  const currencyOptions = [
    { label: "$ (USD)", value: "USD" },
    { label: "€ (EUR)", value: "EUR" }
  ];

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          configuration: formInitialValues()
        }}
        onSubmit={({ setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then((response) => {
            if (response.data.error === null) {
              updateFunc(response.data.config);
              back();
            } else {
              alertError(response.data.error);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <h2>Housing Dates</h2>
              <p>Set the date range for check-in and check-out.</p>
              <div className="sg-mgmt-form-row">{renderDateField("Date Begin", "configuration[date_begin]")}</div>
              <div className="sg-mgmt-form-row">{renderDateField("Date End", "configuration[date_end]")}</div>
              <div className="sg-mgmt-form-row">
                {renderDateField("Default Check-in Date", "configuration[check_in_date_default]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderDateField("Default Check-out Date", "configuration[check_out_date_default]")}
              </div>
            </div>
            <div className="sg-mgmt-form-container">
              <h2>Additional Settings</h2>
              <div className="sg-mgmt-form-row">
                {renderSelectField("Currency", "configuration[currency]", currencyOptions)}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", back)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <PageHeader text="Housing Settings" />
      <div className="my-2">{renderForm()}</div>
    </div>
  );
};

export default HousingConfigurationEdit;
