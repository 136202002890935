import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const Housing = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      { label: "Overview", path: "/housing" },
      { label: "Hotels", path: "/housing/hotels" },
      { label: "Bookings", path: "/housing/bookings" },
      { label: "Room Blocks", path: "/housing/room_blocks" },
      { label: "Reports", path: "/housing/reports" }
    ]

    if (user && (user.role != "basic" || user.permission.housing_edit)) {
      tabs.push({
        label: "Settings",
        path: "/housing/settings",
        icon: TabSettingsIcon
      });
    }

    return tabs;
  };

  return (
    <RouterTabbedPage
      tabs={getTabs()}
    />
  );
};

export default Housing;
