import React, { useContext } from "react";

import { SnackbarProvider } from "notistack";

import EventContext from "@event/EventContext";
import ContentFrame from "@shared/ContentFrame";
import ErrorFallback from "@shared/ErrorFallback";
import { useEventMetadata } from "@shared/hooks/useEventMetadata";
import Loading from "@shared/Loading";
import MuiAlert from "@shared/MuiAlert";
import { useAppsignal } from "@shared/useAppsignal";

import MainEventForm from "../../main/MainEventForm";

const EventEdit = () => {
  const { event } = useContext(EventContext).values;
  const { appsignal, ErrorBoundary } = useAppsignal();
  const eventMetadata = useEventMetadata(event.id);

  if (eventMetadata.isLoading) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  return (
    <ErrorBoundary instance={appsignal} tags={{ tag: "EventEdit" }} fallback={(_error) => <ErrorFallback />}>
      <ContentFrame>
        <SnackbarProvider
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3500}
          Components={{
            mui: MuiAlert
          }}
        >
          {event ? <MainEventForm event={event} eventMetadata={eventMetadata} rootUrl="/" isEdit /> : <Loading />}
        </SnackbarProvider>
      </ContentFrame>
    </ErrorBoundary>
  );
};

export default EventEdit;
