import React, { useContext, useState, useEffect } from "react";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router";
import urljoin from "url-join";

import { getReadableContentType } from "@contents/ContentTypes";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import { formatTime } from "@shared/TimeUtils";

const WebPageTable = ({ fetchUrl }) => {
  const confirm = useConfirm();
  const [fetched, setFetched] = useState(false);
  const { apiRoot, event } = useContext(EventContext).values;
  const [webPages, setWebPages] = useState([]);
  const { user } = useContext(EventUserContext);

  const columns = [
    {
      field: "name",
      headerName: "Name"
    },
    {
      field: "slug",
      headerName: "Identifier"
    },
    {
      field: "content_type",
      headerName: "Content Type",
      renderCell: (params) => {
        return getReadableContentType(params.value);
      }
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      renderCell: (params) => {
        return formatTime(params.value, event.time_zone);
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      getActions: (params) => [renderEdit(params.row), renderDelete(params.row)]
    }
  ];

  const renderEdit = (item) => {
    return (
      <>
        <Link className="cursor-pointer" to={`/content/web/pages/${item.id}`}>
          Edit
        </Link>
      </>
    );
  };

  const renderDelete = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            attemptDelete(item);
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const attemptDelete = (item) => {
    confirm({
      title: "Confirm removal",
      description: "Are you sure you want to remove this web page? This cannot be undone."
    })
      .then(() => {
        performDelete(item);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const performDelete = (item) => {
    axios
      .delete(urljoin(apiRoot, "/contents/web_pages", `/${item.id}`))
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess("Web Page deleted");
          setWebPages(webPages.filter((webPage) => webPage.id !== item.id));
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  useEffect(() => {
    const fetchWebPages = async () => {
      try {
        const result = await axios(fetchUrl ? fetchUrl : urljoin(apiRoot, `contents/web_pages`));
        setWebPages(result.data["web_pages"]);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchWebPages();
  }, [apiRoot, fetchUrl]);

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.content_web_pages_edit) {
      return false;
    }
    return true;
  };

  if (!fetched) {
    return <Loading />;
  }

  return (
    <GrowlTable
      columns={columns}
      items={webPages}
      sortColumn="name"
      sortDirection="asc"
      tableName={`${event.slug}-web-pages`}
    />
  );
};

export default WebPageTable;
