import React, { useContext, useEffect } from "react";

import { useNavigate } from "react-router";

import EventUserContext from "@event/EventUserContext";
import ContentFrame from "@shared/ContentFrame";

import StaticFilesIndex from "./StaticFilesIndex";

const StaticFiles = () => {
  const { user } = useContext(EventUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === "basic" && !user.permission.content_static_files_view) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <ContentFrame>
      <StaticFilesIndex key={`static-files-index`} />
    </ContentFrame>
  );
};

export default StaticFiles;
