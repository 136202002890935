import React, { useContext, useState } from "react";

import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import GrowlTableStatic from "@shared/GrowlTableStatic";
import { useDeleteHousingBlock } from "@shared/hooks/useHousingBlocks";
import { requiredProp } from "@shared/PropUtils";

import HousingBlankRoomBlock from "./HousingBlankRoomBlock";
import HousingRoomBlockModal from "./HousingRoomBlockModal";

const HousingRoomBlocksIndex = (props) => {
  const { blocks, goEdit, goRooms, hotels, setEditBlockId } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const deleteHousingBlock = useDeleteHousingBlock(apiRoot, event.id);
  requiredProp("blocks", blocks);
  requiredProp("goEdit", goEdit);
  requiredProp("goRooms", goRooms);
  requiredProp("setEditBlockId", setEditBlockId);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.housing_edit) {
      return false;
    }
    return true;
  };

  const performDelete = (id) => {
    deleteHousingBlock.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            window.location.reload();
          } else {
            alertError(data.error);
          }
        },
        onError: (error) => {
          alertError(error);
        }
      }
    );
  };

  const deleteConfirmMsg = () => (
    <>
      <p>Are you sure you want to delete this room block?</p>
    </>
  );

  const renderDelete = (block) => {
    if (block.room_block_room_types.length !== 0 || !editEnabled()) {
      return <></>;
    }

    return (
      <>
        <GrowlModal
          content={deleteConfirmMsg()}
          title={`Delete Room Block`}
          trigger={
            <span className="inline-block cursor-pointer border-r border-r-ui-7 pr-2 uppercase text-ui-7">Delete</span>
          }
          actions={[
            {
              label: "Cancel",
              close: true
            },
            {
              label: "Delete",
              close: true,
              func: () => {
                performDelete(block.id);
              }
            }
          ]}
        />
      </>
    );
  };

  const renderManageAction = (block) => {
    if (block.main) {
      return <></>;
    }

    return (
      <>
        <span
          className="inline-block cursor-pointer border-r border-r-ui-7 px-2 uppercase text-ui-7"
          onClick={() => {
            setEditBlockId(block.id);
            goRooms();
          }}
        >
          Manage
        </span>
      </>
    );
  };

  const renderEditAction = (block) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer pl-2 uppercase text-ui-7"
          onClick={() => {
            setEditBlockId(block.id);
            goEdit();
          }}
        >
          Edit Info
        </span>
      </>
    );
  };

  const generateStaticRowData = (block) => {
    if (!block) {
      return null;
    }

    return {
      key: block.id,
      cells: [
        <>{block.name}</>,
        <>{block.code}</>,
        <>
          {block.owner_name_first}
          &nbsp;
          {block.owner_name_last}
        </>,
        <>{block.notes}</>,
        <>
          <div className="text-right text-[13px] font-medium leading-none">
            {renderDelete(block)}
            {renderManageAction(block)}
            {renderEditAction(block)}
          </div>
        </>
      ]
    };
  };

  const renderBlockTableStatic = () => {
    const mainBlock = blocks.filter((blk) => blk.main)[0];
    const nonMainBlocks = blocks.filter((blk) => !blk.main);

    const columns = ["Room Block Name", "Code", "Owner", "Notes", "Actions"];
    const items = [generateStaticRowData(mainBlock), ...nonMainBlocks.map((b) => generateStaticRowData(b))];
    return (
      <div className="my-2">
        <GrowlTableStatic columns={columns} items={items} />
      </div>
    );
  };

  const renderNewBlockButton = () => {
    return renderCreateButton("Add Room Block", () => {
      setModalAddVisible(true);
    });
  };

  if (hotels.length == 0) {
    return (
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <p>To begin using the Housing module:</p>
        <ol className="ml-2 mt-1">
          <li>
            {"1. Configure dates and other settings in the "}
            <MuiLink component={Link} className="cursor-pointer" to={`/housing/settings`}>
              SETTINGS
            </MuiLink>
            {" tab"}
          </li>
          <li>
            {"2. Create and manage hotels in the "}
            <MuiLink component={Link} className="cursor-pointer" to={`/housing/hotels`}>
              HOTELS
            </MuiLink>
            {" tab"}
          </li>
        </ol>
      </div>
    );
  }

  return (
    <div>
      {renderNewBlockButton()}
      {renderBlockTableStatic()}
      <HousingRoomBlockModal
        block={HousingBlankRoomBlock}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Room Block"
      />
    </div>
  );
};

export default HousingRoomBlocksIndex;
