import { useQuery } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchEvents = async () => {
  return request({
    method: "get",
    url: urljoin("/-/events")
  });
};

export const useEvents = () => {
  return useQuery({
    queryKey: ["events"],
    queryFn: () => fetchEvents(),
    cacheTime: 30 * (1000 * 60)
  });
};
