import React, { useContext, useState } from "react";

import Stack from "@mui/material/Stack";
import { Formik, Form } from "formik";

import EventContext from "@event/EventContext";
import { renderSubmitButton, renderCancelButton, renderSelectFieldFilterable } from "@shared/FormUtils";
import { useEvents } from "@shared/hooks/useEvents";
import { useCopyMetadataFieldsFromEvent } from "@shared/hooks/useMetadata";
import Loading from "@shared/Loading";

const MetadataCopyEventForm = (props) => {
  const { fieldType, cancelButton = () => {}, callbackSuccess = () => {}, callbackFailure = () => {} } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [isSubmitting, setSubmitting] = useState(false);
  const copyMetadataFieldsFromEvent = useCopyMetadataFieldsFromEvent(apiRoot, event.id);
  const events = useEvents();

  if (events.isPending) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  if (events.isError) {
    return (
      <div className="w-full">
        <div className="alert alert-danger">Error loading events</div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        duplicating_event_gid: null,
        field_type: fieldType
      }}
      onSubmit={async (values) => {
        setSubmitting(true);

        const callbacks = {
          onSuccess: (data) => {
            if (data.error === null) {
              callbackSuccess(data);
            } else {
              callbackFailure(data);
            }
            setSubmitting(false);
          },
          onError: (error) => {
            callbackFailure(error?.response?.data);
            setSubmitting(false);
          }
        };

        copyMetadataFieldsFromEvent.mutate({ data: values }, callbacks);
      }}
    >
      <Form className="sg-mgmt-form" id="sg-mgmt-form-custom-field-clone">
        <div className="sg-mgmt-form-container">
          <h2>Select an event to copy</h2>
          <div className="sg-mgmt-form-row">
            This will add all custom fields from the selected event to the current event.
            <br />
            <br />
          </div>

          <div className="sg-mgmt-form-row">
            <br />
            <br />
            {renderSelectFieldFilterable(
              "Event",
              "duplicating_event_gid",
              [{ value: null, label: "" }].concat(
                events.data.map((x) => {
                  return {
                    value: x.gid,
                    label: x.name
                  };
                })
              ),
              false,
              true
            )}
          </div>

          <Stack direction="row" spacing={2}>
            {renderSubmitButton("Save", isSubmitting)}
            {renderCancelButton("Cancel", cancelButton)}
          </Stack>
        </div>
      </Form>
    </Formik>
  );
};

export default MetadataCopyEventForm;
