import React from "react";

import { Link } from "react-router";

import { renderButton } from "@components/shared/FormUtils";
import ContentFrame from "@shared/ContentFrame";
import PageHeader from "@shared/PageHeader";

const Help = () => {
  return (
    <ContentFrame>
      <PageHeader text="Tutorials" />
      <p>
        Need help getting started or want to learn how to make the best of the many features SilentGrowl has to offer?
        <br />
        Visit our tutorial portal and browse numerous tutorials providing concise overviews and step by step
        instructions from managing participant and session records, sending emails to setting up your own dashboard
        analytics and more.
      </p>
      <div className="pt-8" />
      {renderButton("Go to tutorial portal", () => {
        window.location.href = "#";
      })}
      <div className="pt-16" />
      <PageHeader text="Need Help" />
      <p>See an issue or need to report a bug? Please contact us and we’ll get back to you as soon as possible.</p>
      <div className="pt-8" />
      {renderButton("Report an issue", () => {
        window.open(
          `mailto:cave@jaguardesignstudio.com?subject=${"Report an issue with SilentGrowl".replaceAll(" ", "%20")}`,
          "_blank"
        );
      })}
      <div className="pt-16" />
      <PageHeader text="Privacy Policy" />
      <p>View the SilentGrowl Privacy Policy</p>
      <div className="pt-8" />
      <Link to="/help/privacy">{renderButton("Privacy Policy", () => {})}</Link>
    </ContentFrame>
  );
};

export default Help;
