import React from "react";

import ContentFrame from "@shared/ContentFrame";

import FeaturesOverview from "./features/FeaturesOverview";

const Features = () => {
  return (
    <ContentFrame>
      <FeaturesOverview />
    </ContentFrame>
  );
};

export default Features;
