import React, { useState } from "react";

import EventMenuDropdown from "./EventMenuDropdown";
import EventMenuExpandRetract from "./EventMenuExpandRetract";
import EventMenuItem from "./EventMenuItem";

const EventMenu = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  return (
    <div
      className={`sg-mgmt-menu bg-gradient-to-l from-sky-50 to-slate-50 ${menuExpanded ? "sg-mgmt-menu-expanded" : ""}`}
    >
      <EventMenuItem requiredPermissions={[]} icon="dashboard" link="/" menuExpanded={menuExpanded} title="Dashboard" />
      <EventMenuItem
        requiredPermissions={["participants_view"]}
        icon="attendees"
        link="/participants"
        menuExpanded={menuExpanded}
        title="Participants"
      />
      <EventMenuItem
        requiredPermissions={["communications_view"]}
        icon="communications"
        link="/communications"
        menuExpanded={menuExpanded}
        title="Communications"
      />
      <EventMenuItem
        requiredPermissions={["sessions_view"]}
        icon="sessions"
        link="/sessions"
        title="Sessions"
        menuExpanded={menuExpanded}
        activeLinks={["/sessions", "/session/"]}
      />
      <EventMenuDropdown
        icon="forms"
        title="Form Builder"
        menuExpanded={menuExpanded}
        onOpen={() => setMenuExpanded(true)}
        links={[
          {
            title: "Registration",
            link: "/forms/registration",
            requiredPermissions: ["forms_view"]
          },
          {
            title: "Surveys",
            link: "/forms/survey",
            requiredPermissions: ["forms_view"]
          },
          {
            title: "Other",
            link: "/forms/miscellaneous",
            requiredPermissions: ["forms_view"]
          }
        ]}
      />
      <EventMenuItem
        requiredPermissions={["housing_view"]}
        icon="housing"
        link="/housing"
        menuExpanded={menuExpanded}
        title="Housing"
      />
      <EventMenuItem
        requiredPermissions={["meetings_view"]}
        icon="meetings"
        link="/meetings"
        menuExpanded={menuExpanded}
        title="Meetings"
      />
      <EventMenuItem
        requiredPermissions={["ticketing_view"]}
        icon="ticketing"
        link="/ticketing"
        menuExpanded={menuExpanded}
        title="Ticketing"
      />
      <EventMenuItem
        requiredPermissions={["reports_view"]}
        icon="reports"
        link="/reporting"
        menuExpanded={menuExpanded}
        title="Reports"
      />
      <EventMenuDropdown
        icon="content"
        onOpen={() => setMenuExpanded(true)}
        links={[
          {
            title: "Static Files",
            link: "/content/static_files",
            requiredPermissions: ["content_static_files_view"]
          },
          {
            title: "Web Pages",
            link: "/content/web",
            requiredPermissions: ["content_web_pages_view"]
          }
        ]}
        menuExpanded={menuExpanded}
        title="Content"
      />
      <EventMenuDropdown
        icon="settings"
        onOpen={() => setMenuExpanded(true)}
        links={[
          {
            title: "Event",
            link: "/edit",
            requiredPermissions: ["configuration_event_view"]
          },
          {
            title: "Custom Fields",
            link: "/custom_fields",
            requiredPermissions: ["configuration_custom_fields_view"]
          },
          {
            title: "Features",
            link: "/features",
            requiredPermissions: ["configuration_features_view"]
          },
          {
            title: "APIs",
            link: "/apis",
            requiredPermissions: ["configuration_api_view"]
          },
          {
            title: "Cache",
            link: "/cache",
            requiredPermissions: ["configuration_cache_view"]
          },
          {
            title: "Users",
            link: "/users",
            requiredPermissions: ["configuration_users_view"]
          }
        ]}
        menuExpanded={menuExpanded}
        title="Configurations"
      />
      <EventMenuItem
        requiredPermissions={["help_view"]}
        icon="help"
        link="/help"
        menuExpanded={menuExpanded}
        title="Help"
      />
      <EventMenuExpandRetract menuExpanded={menuExpanded} onClick={() => setMenuExpanded(!menuExpanded)} />
    </div>
  );
};

export default EventMenu;
