import React from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { Field } from "formik";

const CheckFieldSmall = ({ fieldName, label, disabled }) => (
  <Field name={fieldName}>
    {({ field }) => (
      <label>
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              {...field}
              name={fieldName}
              checked={field.value == true}
              value={true}
              size="small"
              style={{ padding: "0 9px 0 0" }}
            />
          }
          label={label}
          sx={{
            mr: 3,
            ".MuiFormControlLabel-label": {
              fontSize: 12
            }
          }}
        />
      </label>
    )}
  </Field>
);

export default CheckFieldSmall;
