import React from "react";

import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";

import ApisTokenForm from "./ApisTokenForm";

const ApisTokenModal = (props) => {
  const { modalVisible, resetModal, title, token, updateFunc } = props;

  Modal.setAppElement("#root");

  const success = (response) => {
    alertSuccess("Token saved successfully");
    updateFunc(response.data.token);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to save token");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <ApisTokenForm callbackFailure={failure} callbackSuccess={success} cancelButton={resetModal} token={token} />
        </div>
      </div>
    </Modal>
  );
};

export default ApisTokenModal;
