import React, { useContext } from "react";

import { Link } from "react-router";

import EventUserContext from "@event/EventUserContext";
import { renderCreateButton } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

import WebPageTable from "./WebPageTable";

const WebPageIndex = () => {
  const { user } = useContext(EventUserContext);
  const renderAddWebPagesButton = () => {
    if (editEnabled()) {
      return <Link to="/content/web/pages/new">{renderCreateButton("Add Web Page", () => {})}</Link>;
    }
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.content_web_pages_edit) {
      return false;
    }
    return true;
  };

  const renderWebPagesTable = () => {
    return <WebPageTable />;
  };

  return (
    <div>
      <PageHeader text="Web Pages" />
      {renderAddWebPagesButton()}
      <div className="mt-8">
        {renderWebPagesTable()}
      </div>
    </div>
  );
};

export default WebPageIndex;
