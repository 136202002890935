import React, { useContext, useState } from "react";

import { FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetFilterByTag from "@dashboard/form/WidgetFilterByTag";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";

const OverviewWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const [tagOptions, setTagOptions] = useState([]);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "commsOverviewByTemplate",
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 2,
      widget_config: {
        overview: widget?.widget_config?.overview || {
          sortBy: "alpha",
          availableTotal: true,
          assigned: true,
          assignedPercent: true,
          available: true
        },
        filters: widget?.widget_config?.filters || {
          tag: ""
        }
      }
    }
  });
  const dataSetWatch = watch("widget_data_set");

  const dataSets = [
    { value: "commsOverviewByTemplate", label: "Communications Overview (by Template)" },
    { value: "commsOverviewByTag", label: "Communications Overview (by Tag)" },
    { value: "ticketsOverview", label: "Tickets Overview" },
    { value: "packagesOverview", label: "Packages Overview" }
  ];

  useQuery({
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/ticketing/ticket_tags"), { signal })
        .then((res) => setTagOptions(res.data.tags))
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // add in type
    submitData.widget_type = "overview";

    // set fixed size
    submitData.widget_size = 2;

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((result) => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderSortBy = () => {
    return (
      <div className="mb-4">
        <h3>Sort By:</h3>
        <div className="mt-2 flex-1 flex-grow align-bottom">
          <FormControl>
            <Controller
              name={`widget_config[overview][sortBy]`}
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup {...field} onChange={onChange} value={value}>
                  <FormControlLabel value="alpha" control={<Radio />} label="Alphabetically" />
                  <FormControlLabel value="start_datetime" control={<Radio />} label="Start Date" />
                  <FormControlLabel value="end_datetime" control={<Radio />} label="End Date" />
                </RadioGroup>
              )}
            />
          </FormControl>
        </div>
      </div>
    );
  };

  const ticketOverviewColumns = [
    { label: "Available Total", value: "availableTotal" },
    { label: "Assigned", value: "assigned" },
    { label: "Assigned Percent", value: "assignedPercent" },
    { label: "Available", value: "available" }
  ];

  const renderColumns = () => {
    return (
      <div className="mb-4">
        <h3>Columns:</h3>
        {ticketOverviewColumns.map((col) => (
          <div className="mt-2 flex-1 flex-grow align-bottom" key={`overview-column-${col.value}`}>
            <FormControl>
              <Controller
                name={`widget_config[overview][${col.value}][selected]`}
                control={control}
                defaultValue={false}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    label={col.label}
                    control={
                      <Checkbox checked={value} onChange={onChange} value={value} variant="outlined" size="small" />
                    }
                  />
                )}
              />
            </FormControl>
          </div>
        ))}
      </div>
    );
  };

  const renderDataSetConfig = () => {
    switch (dataSetWatch) {
      case "ticketsOverview":
        return (
          <>
            {renderColumns()}
            {renderSortBy()}
            <WidgetFilterByTag control={control} tags={tagOptions} />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} placeholder={"i.e. Communications Overview"} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderDataSetConfig()}
        </div>
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Overview widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

export default OverviewWidgetSettings;
