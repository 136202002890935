import React, { useContext } from "react";

import { Stack } from "@mui/material";
import AutoNumeric from "autonumeric";
import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCurrencyField, renderTextField, renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { useUpdateHousingBlockRoomTypeInventory } from "@shared/hooks/useHousingBlocks";
import { formatTextDateFriendly } from "@shared/TimeUtils";

const HousingRoomBlockRoomTypeInventoryForm = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { cancelButton = () => { }, blocks, config, block, roomBlockRoomType } = props;
  const updateHousingBlockRoomTypeInventory = useUpdateHousingBlockRoomTypeInventory(apiRoot, event.id);

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.housing_edit) {
      return false;
    }
    return true;
  };

  const formInitialValues = () => {
    const inventoryDates = [];
    const dates = roomBlockRoomType.room_block_room_type_dates;
    dates.forEach((date) => {
      const values = {
        unit_count: date.unit_count,
        rate_in_cents: (parseInt(date.rate_in_cents, 10) / 100)
      };
      inventoryDates[date.id] = values;
    });
    return inventoryDates;
  };

  const renderForm = () => {
    const dates = roomBlockRoomType.room_block_room_type_dates;
    const mainBlock = blocks.find((blk) => blk.main);
    const mainBlockRoomBlockRoomType = mainBlock.room_block_room_types.find(
      (rbrt) => rbrt.room_type_id == roomBlockRoomType.room_type_id
    );
    return (
      <Formik
        initialValues={{
          dates: formInitialValues()
        }}
        onSubmit={({ setSubmitting }) => {
          if (!editEnabled()) {
            alertError("Unauthorized");
            setSubmitting(false);
            return;
          }

          const form = document.getElementById("sg-mgmt-form-inventory-edit");
          const formData = new FormData(form);

          // unformat Autonumeric fields and convert rates to integer (cents)
          dates.forEach((date) => {
            const valueUnformatted = AutoNumeric.getNumericString(`input[name="dates[${date.id}][rate_in_cents]"]`);
            formData.set(`dates[${date.id}][rate_in_cents]`, valueUnformatted * 100);
          });

          updateHousingBlockRoomTypeInventory.mutate(
            {
              blockId: block.id,
              typeId: roomBlockRoomType.id,
              data: formData
            },
            {
              onSuccess: (data) => {
                if (data.error === null) {
                  alertSuccess("Room inventory updated.");
                  cancelButton();
                } else {
                  alertError(`Room inventory update failed: ${data.error}`);
                  setSubmitting(false);
                }
              },
              onError: (error) => {
                alertHttpError(error);
              }
            }
          );
        }}
      >
        {({ values, setValues, isSubmitting }) => (
          <Form className="sg-mgmt-form" id="sg-mgmt-form-inventory-edit">
            <div className="sg-mgmt-form-container">
              <div className="float-right">
                <span className="sg-mgmt-link text-[13px]" onClick={() => {
                  const newValues = {};
                  const sortedDates = sortBy(dates, (d) => d.date);
                  const currentFormValueFirstDate = values.dates[sortedDates[0].id];
                  dates.forEach((date) => {
                    newValues[date.id] = {
                      unit_count: currentFormValueFirstDate.unit_count,
                      rate_in_cents: currentFormValueFirstDate.rate_in_cents
                    };
                  });
                  setValues({ dates: newValues });
                }}>Match All Dates</span>
              </div>
              {sortBy(dates, (d) => d.date).map((date) => {
                // corresponding main block date
                // TODO: find here
                return (
                  <div key={`room-type-date-${date.id}`}>
                    <div className="sg-mgmt-form-row">
                      <h3>{formatTextDateFriendly(date.date)}</h3>
                    </div>
                    <div className="sg-mgmt-form-row">
                      <div className="flex justify-start">
                        <div className="mr-4">
                          {renderTextField("Inventory (# of available rooms)", `dates[${date.id}][unit_count]`, [], !editEnabled())}
                        </div>
                        <div className="mr-4">
                          {renderCurrencyField("Rate", `dates[${date.id}][rate_in_cents]`, config.currency_symbol, [], !editEnabled())}
                        </div>
                        <div>
                          <div className="sg-mgmt-form-input-container">
                            <label>Available in MAIN block</label>
                            <div className="mt-1 text-center">
                              <span className="text-lg font-bold">
                                {
                                  mainBlockRoomBlockRoomType.room_block_room_type_dates.find(
                                    (rbrtd) => rbrtd.date === date.date
                                  ).units_available
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Stack direction="row" spacing={2}>
              {editEnabled() && renderSubmitButton("Update", isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  if (!config.date_begin || !config.date_end) {
    return <div>You must set the check in/out date ranges in Settings before you can manage room inventory.</div>;
  }

  return <div>{renderForm()}</div>;
};

export default HousingRoomBlockRoomTypeInventoryForm;
