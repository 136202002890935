import React from "react";

import ContentFrame from "@shared/ContentFrame";
import PageHeader from "@shared/PageHeader";

const Privacy = () => {
  return (
    <ContentFrame>
      <PageHeader text="Privacy Policy" />
      <main className="font-proxima font-normal">
        <div className="px-20 text-black">
          <div className="py-2">Last Updated: March 7, 2025</div>
          <div className="py-2">
            <h2 className="text-lg font-bold">Introduction</h2>
          </div>
          <div className="py-2">
            SilentGrowl Inc. (&quot;SilentGrowl,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) values the
            privacy of our users. This Privacy Policy details how we collect, use, share, and safeguard personal data on
            our website (<a href="http://www.silentgrowl.com">www.silentgrowl.com</a>) and our virtual event platform.
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">Scope</h2>
          </div>
          <div className="py-2">
            This Privacy Policy (&quot;Privacy Policy&quot;) is part of our Terms and Conditions and applies to all
            visitors to our website and further applies to our virtual event platform and all registrants, presenters,
            exhibitors, sponsors, and other persons attending or participating in our virtual events.
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">Information We Collect, Store, and Use</h2>
          </div>
          <ol className="ml-4 list-decimal" start="1" type="1">
            <li>
              <h2 className="text-lg font-bold">Registration Information and Other Identifiers:</h2>
              When you create a SilentGrowl account or register for any event, we store and use information you provide
              during the account creation and/or event registration processes, such as first and last name, email
              address, phone number, postal address, login and password information, company, job title, country,
              industry sector, and any additional data provided.
            </li>
            <li>
              <h2 className="text-lg font-bold">Event Activity:</h2> Events attended, resources downloaded, interactions
              with other participants, and survey responses.
            </li>
            <li>
              <h2 className="text-lg font-bold">Technical Information:</h2> IP address, network connection type, browser
              type, device information, operating system, internet service provider, geolocation information, dates and
              times of access and the duration and stop time of such access, and the pages, flies, documents and links
              that you visit.
            </li>
            <li>
              <h2 className="text-lg font-bold">Cookies and Tracking Technologies:</h2> We may use cookies and similar
              technologies to recognize you, enhance your experience, and analyze platform usage.
            </li>
            <li>
              <h2 className="text-lg font-bold">Payment information:</h2> We use a third party payment processor for
              event registration, and we do not store your payment information relating to events or event registration
              on our computer servers, with the following exception: We may collect and store payment information
              relating to payments for matters that are peripheral to attending and participating in
              events-specifically, housing and ticketing for special events.
            </li>
          </ol>
          <div className="py-2">
            <h2 className="text-lg font-bold"></h2>
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">How We Use Your Information</h2>
          </div>
          <div className="py-2">We use your information relating to the following matters:</div>
          <ol className="ml-4 list-decimal" start="1" type="1">
            <li>
              <h2 className="text-lg font-bold">Manage and deliver events:</h2> To facilitate event registration and
              participation, provide access to event content, communicate important updates, and detect or prevent
              fraud.
            </li>
            <li>
              <h2 className="text-lg font-bold">Platform improvement:</h2> To Analyze usage data to optimize platform
              functionality and user experience.
            </li>
            <li>
              <h2 className="text-lg font-bold">Networking and communication:</h2> To enable event registrants,
              presenters, exhibitors, sponsors, and other persons attending or participating in our virtual events to
              connect, message one another (if the user enables messaging on our virtual platform), and participate in
              discussions.
            </li>
            <li>
              <h2 className="text-lg font-bold">Marketing and Promotional Activities:</h2> We may send communications
              about events and services, including via emails, notifications, text messages, and or other
              communications. You may opt out of these communications at any time.
            </li>
            <li>
              <h2 className="text-lg font-bold">Privacy Policy Enforcement:</h2> To enforce this Privacy Policy and any
              other terms and conditions you have agreed to, including protecting SilentGrowl&apos;s rights, property,
              copyright-protected content, or other intellectual property.
            </li>
          </ol>
          <div className="py-2">
            <h2 className="text-lg font-bold"></h2>
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">Sharing Your Information</h2>
          </div>
          <div className="py-2">We may share your information as follows:</div>
          <ol className="ml-4 list-decimal" start="1" type="1">
            <li>
              <h2 className="text-lg font-bold">Event Sponsors/Exhibitors:</h2> We may share your information with event
              exhibitors, sponsors, and presenters, unless you opt out of such sharing.
            </li>
            <li>
              <h2 className="text-lg font-bold">Service Providers:</h2> We may share necessary information with third
              party vendors who support our platform or event execution (for example, video streaming providers and
              analytics partners).
            </li>
            <li>
              <h2 className="text-lg font-bold">Legal Compliance:</h2> We may disclose information when required by law
              to protect our rights, or in response to valid legal requests.
            </li>
          </ol>
          <div className="py-2">
            <h2 className="text-lg font-bold"></h2>
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">Data Security</h2>
          </div>
          <div className="py-2">
            We implement technical and organizational measures to safeguard your data, including:
          </div>
          <ol className="ml-4 list-decimal" start="1" type="1">
            <li>Encryption of data in transit and at rest.</li>
            <li>Regular security assessments and vulnerability scans.</li>
            <li>Strict access controls and employee training.</li>
          </ol>
          <div className="py-2">
            <h2 className="text-lg font-bold">State and Country Specific Rights</h2>
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold"></h2>
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">A. State-Specific Privacy Rights (United States)</h2>
          </div>
          <div className="py-2">
            Pursuant to certain state privacy laws in the USA, individuals residing in certain USA states may have
            additional rights regarding our collection, use, and sharing of their personal information. This Section A
            applies to residents of states including California, Colorado, Connecticut, Delaware, Iowa, Montana,
            Nebraska, New Hampshire, New Jersey, Oregon, Texas, Utah, and Virginia and provides additional information
            in accordance with the privacy laws (current or forthcoming) in those states (collectively, the &quot;State
            Privacy Laws&quot;). The exact scope of these rights may vary by state.
          </div>
          <div className="py-2">
            We will not discriminate against you for exercising your rights under any State Privacy Laws. Please note
            that the various rights described in this Section A may be limited to residents of the states listed above
            and may not be available to all users.
          </div>
          <div className="py-2">
            The State Privacy Laws may provide residents of certain USA states with some or all of the rights set forth
            below. Please note, however, that these rights are not absolute and may be subject to certain exceptions.
            For this reason, Silent Growl may decline requests made under the State Privacy Laws in certain instances,
            in accordance with applicable law. Subject to any exceptions or limitations, and based on your location,
            these rights may include:
          </div>
          <div className="py-2">
            <li>
              <em>Right to Access</em>: The right to request a copy of the specific pieces of personal information that
              we have collected about you in the previous twelve (12) months. The information will be delivered by mail
              or electronically. Upon receipt of a verifiable consumer request, described below, we will disclose: (i)
              The categories of personal information we have collected about you; (ii) The categories of sources from
              which personal information is collected; (iii) Our business purpose for collecting personal information;
              (iv) The categories of third parties with whom we share personal information, if any; and (v) The specific
              pieces of personal information we have collected about you.
            </li>
          </div>
          <div className="py-2">
            <li>
              <em>Right to Data Portability</em>: The right to receive your personal information in a portable, readily
              usable format that allows you to transmit your information to another entity without hindrance.
            </li>
          </div>
          <div className="py-2">
            <li>
              <em>Right to Correct Inaccurate Information</em>: The right to request that we correct inaccurate
              information about you that we maintain.
            </li>
          </div>
          <div className="py-2">
            <li>
              <em>Right to Deletion</em>: The right to request that we delete certain personal information.
            </li>
          </div>
          <div className="py-2">
            <li>
              <em>Right to Be Free from Discrimination</em>: The right to not be discriminated against by us for
              exercising any of your rights. For example, we will not: (i) Deny goods or services to you; (ii) Charge
              different prices or rates for goods or services, including through the use of discounts or other benefits
              or imposing penalties; (iii) Provide a different level or quality of goods or services to you; or (iv)
              Suggest that you will receive a different price or rate for goods or services or a different level or
              quality of goods or services, except to the extent permitted by law.
            </li>
          </div>
          <div className="py-2">
            In addition to the above, pursuant to State Privacy Laws, you may have the right to opt-out of our
            processing or &quot;sharing&quot; of your personal information for one or more of the following purposes:
            (i) personalized or targeted advertising; (ii) sales; or (iii) profiling in furtherance of decisions that
            produce legal or similarly significant effects. You may also have the right to limit, opt-out of, or require
            consent to process your sensitive personal information.
          </div>
          <div className="py-2">
            Nevada also provides its residents with a limited right to opt-out of certain personal information sales.
            SilentGrowl does not sell your personal information to third parties as defined in Nevada law, and will not
            do so in the future without providing you with notice and an opportunity to opt-out of such sale as required
            by law. To exercise your opt-out rights, of if you have any questions regarding our data privacy practices
            or our compliance with Nevada data privacy law, please contact us at support@silentgrowl.com.
          </div>
          <div className="py-2">
            <h2 className="text-lg font-bold">B. California Residents: Additional Privacy Disclosures</h2>
          </div>
          <div className="py-2">
            Pursuant to the California Consumer Privacy Act of 2018 (as amended by the California Privacy Rights Act of
            2020) and its implementing regulations (collectively, the &quot;CCPA&quot;), SilentGrowl makes the following
            additional disclosures regarding its collection, use, and sharing of personal information:
          </div>
          <div className="py-2">
            <u>Categories of Information Collected, Disclosed, and Shared</u>: The categories of personal information we
            collect and the sources from which we collect such information are described above in this Privacy Policy;
            the business purposes for which we use this information are described above in this Privacy Policy; and the
            categories of third parties to whom we may disclose this information are described above in this Privacy
            Policy Privacy Policy. We have not sold consumers&apos; personal information in the preceding twelve (12)
            months.
          </div>
          <div className="py-2">
            As further described in our Privacy Policy, in the preceding twelve (12) months, we or our service providers
            may have collected the following categories of personal information, as defined in the CCPA, for a business
            purpose:
          </div>
          <div className="py-2">
            <ul className="ml-4 list-disc">
              <li>Identifiers</li>
              <li>Financial information</li>
              <li>Commercial information</li>
              <li>Internet or other electronic network activity information</li>
              <li>Geolocation data sufficient to identify a precise physical location</li>
              <li>Inferences about preferences and characteristics based on other personal information</li>
            </ul>
            <div className="py-2">
              <u>Sensitive personal information</u>: SilentGrowl does not use or disclose &quot;sensitive personal
              information,&quot; as that term is defined by applicable law, other than to provide you the virtual event
              platform and marketing services described above in this Privacy Policy, as permitted by California law.
              SilentGrowl does not sell or share sensitive personal information for the purpose of cross-context
              behavioral advertising.
            </div>
            <div className="py-2">
              <u>California&apos;s &quot;Shine the Light&quot; Law</u>: Separate from the CCPA, California&apos;s
              &quot;Shine the Light&quot; law permits users of SilentGrowl&apos;s website and virtual event platform
              that are California residents to request certain information regarding our disclosure of personal
              information to third parties for their direct marketing purposes. We do not disclose your personal
              information to third parties for the purpose of directly marketing their goods or services to you unless
              you request such disclosure. If you have any questions regarding this policy, or would like to change your
              preferences, you may contact us at support@silentgrowl.com.
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold">C. Canadian Residents: Your Canadian Privacy Rights</h2>
            </div>
            <div className="py-2">Under applicable Canadian privacy legislation, you have the following rights:</div>
            <div className="py-2">
              <ul className="ml-4 list-disc">
                <li>
                  <em>Access</em>. SilentGrowl permits the reasonable right of access to your personal information. Upon
                  written request and authentication of identity, we will provide you with access to your personal
                  information under our control, subject to any legal requirements and applicable exceptions to the
                  right of access. We will make the information available within thirty days or provide written notice
                  where additional time is required to fulfill the request. Where an access request is refused, we will
                  notify you in writing and provide our rationale for refusal along with a list of next steps available
                  to you.
                </li>
                <li>
                  <em>Accuracy</em>. If you demonstrate the inaccuracy or incompleteness of personal information, we
                  will amend the information as required. If appropriate, we will send the amended information to third
                  party service providers to whom the information has been disclosed.
                </li>
                <li>
                  <em>Complaint</em>. You may also have the right to complain to the Office of the Privacy Commissioner
                  of Canada or the appropriate provincial Information and Privacy Commissioner if you have concerns
                  related to our privacy practices.
                </li>
              </ul>
            </div>
            <div className="py-2">
              If you are a resident of Canada and would like to exercise any of the aforementioned rights, please
              contact us at support@silentgrowl.com.
            </div>
            <div className="py-2">
              Depending on your use of our website or virtual event platform, you may have the following choices:
            </div>
            <div className="py-2">
              <ul className="ml-4 list-disc">
                <li>
                  <em>Cookies Settings and Preferences</em>. You may disable cookies and other tracking technologies
                  through the settings in your web browser. While doing so may negatively affect your experience with
                  the use of website and virtual event platform, it will not prevent us from transacting with you.
                </li>
                <li>
                  <em>Marketing emails</em>. You may sign up for our mailing list to receive promotional and other
                  materials that may be of interest to you. If you no longer wish to receive marketing emails from us,
                  you may choose to unsubscribe at any time by clicking on the &quot;unsubscribe&quot; link in our
                  emails or by contacting us at support@silentgrowl.com.
                </li>
                <li>
                  <em>Opt-Outs</em>. You may contact us to opt out of the use or sharing of your personal information,
                  including for marketing or advertising purposes, and/or the provision of your personal information to
                  our business partners for such purposes.
                </li>
                <li>
                  <em>Online behavioral advertising</em>. We may use Google Analytics on our website or virtual event
                  platform. For more information on how Google Analytics collects and processes information, as well as
                  how you can control the information sent to Google, please review Google&apos;s site &quot;How Google
                  uses data when you use our partners&apos; sites or apps&quot; located at
                  www.google.com/policies/privacy/partners/. You may control your advertising preferences or opt-out of
                  certain Google advertising products (i.e. the Google Analytics cookies) by visiting the Google Ads
                  Preferences Manager, currently available at https://google.com/ads/preferences.
                </li>
              </ul>
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold"></h2>
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold">Use by Children</h2>
            </div>
            <div className="py-2">
              Our website, virtual event platform, and events are not intended for persons under the age of 16. We do
              not seek or knowingly collect personal information from persons under the age of 16, and we request that
              such persons do not provide such information. If you are under 16, you may not use our website or virtual
              event platform and may not attend our events. If we learn we have collected or received personal
              information from an individual under the age of 16, we will take reasonable measures to promptly delete
              such information from our systems.
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold"></h2>
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold">Effective Date</h2>
            </div>
            <div className="py-2">
              This Privacy Policy is effective as of the &quot;Last Updated&quot; date cited above. This version of the
              Privacy Policy replaces and supersedes all prior privacy policies applicable to our website, virtual event
              platform, and marketing services.
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold"></h2>
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold">Changes to This Policy</h2>
            </div>
            <div className="py-2">
              We may update this Privacy Policy. Updates will be posted on our website and virtual event platform with
              the &quot;Last Updated&quot; date modified.
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold"></h2>
            </div>
            <div className="py-2">
              <h2 className="text-lg font-bold">Contact Us</h2>
            </div>
            <div className="py-2">
              If you have any questions regarding this Privacy Policy, please contact us at: support@silentgrowl.com.
            </div>
          </div>
        </div>
      </main>
    </ContentFrame>
  );
};

export default Privacy;
