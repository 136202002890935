import React from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";

import { formatCurrencyCents } from "@shared/CurrencyUtils";
import { requiredProp } from "@shared/PropUtils";
import { formatTextDateFriendly } from "@shared/TimeUtils";

const HousingOverviewRoomType = props => {
  const { config, roomType } = props;
  requiredProp("roomType", roomType);
  const muiTheme = useTheme();

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }));

  const occupancyPercentage = (total, available) => {
    if (total === 0) {
      return "0%";
    }
    return `${Math.round((total - available) / total * 100)}%`;
  };

  const usagePercentage = (total, booked) => {
    if (total === 0) {
      return "0%";
    }
    return `${Math.round((100 - ((total - booked) / total * 100)))}%`;
  };

  const renderTableHead = () => (
    <thead>
      <tr>
        <th className="py-4 px-4 w-1/6 text-left text-white bg-ui-6">&nbsp;</th>
        {sortBy(roomType.room_type_dates, date => date.date).map(date => (
          <th
            className="py-4 px-4 w-1/6 text-left text-white bg-ui-6"
            key={`rt-th-${date.gid}`}
          >
            {formatTextDateFriendly(date.date, true)}
          </th>
        ))}
        <th className="py-4 px-4 w-1/6 text-right text-white bg-ui-6">Total</th>
      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      <tr>
        <td className="py-4 px-4 border-t text-ui-4">Total Inventory</td>
        {sortBy(roomType.room_type_dates, date => date.date).map(date => (
          <td className="py-4 px-4 border-t" key={`rt-total-${date.gid}`}>
            {date.unit_count}
          </td>
        ))}
        <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4">
          {sum(roomType.room_type_dates.map(date => date.unit_count))}
        </td>
      </tr>
      {sortBy(roomType.room_block_room_types, rbrt => !rbrt.main).map(rbrt => (
        <tr key={rbrt.gid}>
          <td className="py-4 px-4 border-t text-ui-4">
            <span className="pl-2 italic">{rbrt.block_name}</span>
          </td>
          {sortBy(rbrt.room_block_room_type_dates, date => date.date).map(
            date => (
              <td
                className="py-4 px-4 border-t"
                key={`rt-occupancy-${date.gid}`}
              >
                {`${date.unit_count} (${date.units_available})`}
                <span className="pl-2">
                  <HtmlTooltip
                    title={
                      <div data-gid={date.gid}>
                        <div>Occupancy: {date.occupancy_count}, {occupancyPercentage(date.unit_count, date.units_available)}</div>
                        {date.occupancy_by_type.map(val => (
                          <div key={val.type}>{val.type} Occupancy: {val.count}, {usagePercentage(date.unit_count, val.count)}</div>
                        ))}
                        <div>Rate: {formatCurrencyCents(date.rate, config.currency_symbol)}</div>
                        <div>GID: {date.gid}</div>
                      </div>
                    }>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </HtmlTooltip>
                </span>
              </td>
            )
          )}
          <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4">
            {sum(rbrt.room_block_room_type_dates.map(date => date.unit_count))}
          </td>
        </tr>
      ))}
      {/* <tr> */}
      {/*   <td className="py-4 px-4 border-t text-ui-4">Booked</td> */}
      {/*   {sortBy(roomType.room_type_dates, date => date.date).map(date => ( */}
      {/*     <td className="py-4 px-4 border-t" key={`rt-occupancy-${date.gid}`}> */}
      {/*       {date.occupancy_count} */}
      {/*     </td> */}
      {/*   ))} */}
      {/*   <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4"> */}
      {/*     {sum(roomType.room_type_dates.map(date => date.occupancy_count))} */}
      {/*   </td> */}
      {/* </tr> */}
      {/* <tr> */}
      {/*   <td className="py-4 px-4 border-t text-ui-4">Available</td> */}
      {/*   {sortBy(roomType.room_type_dates, date => date.date).map(date => ( */}
      {/*     <td className="py-4 px-4 border-t" key={`rt-available-${date.gid}`}> */}
      {/*       {date.units_available} */}
      {/*     </td> */}
      {/*   ))} */}
      {/*   <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4"> */}
      {/*     {sum(roomType.room_type_dates.map(date => date.units_available))} */}
      {/*   </td> */}
      {/* </tr> */}
      {/* <tr> */}
      {/*   <td className="py-4 px-4 border-t text-ui-4">Rate</td> */}
      {/*   {sortBy(roomType.room_type_dates, date => date.date).map(date => ( */}
      {/*     <td className="py-4 px-4 border-t" key={`rt-rate-${date.gid}`}> */}
      {/*       {config.currency_symbol} */}
      {/*       {date.rate_in_cents} */}
      {/*     </td> */}
      {/*   ))} */}
      {/*   <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4"> */}
      {/*     &nbsp; */}
      {/*   </td> */}
      {/* </tr> */}
    </tbody>
  );

  const renderRoomTypeTable = () => (
    <table
      className="w-full table-fixed"
      style={{
        fontFamily: muiTheme.typography.fontFamily,
        fontSize: muiTheme.typography.fontSize
      }}
    >
      {renderTableHead()}
      {renderTableBody()}
    </table>
  );

  const renderRoomTypeName = () => (
    <div>
      Room Type:&nbsp;
      <span className="font-bold text-sg-orange">{roomType.name}</span>
    </div>
  );

  const renderRoomDescription = () => (
    <div>
      Room description:&nbsp;
      {roomType.description}
    </div>
  );

  const renderRoomTypeDetails = () => (
    <div>
      {renderRoomTypeName()}
      {renderRoomDescription()}
    </div>
  );

  const renderRoomType = () => {
    return (
      <div className="py-4 sg-mgmt-hotel-room-type">
        {renderRoomTypeDetails()}
        {renderRoomTypeTable()}
      </div>
    );
  };

  console.log(muiTheme);

  return (
    <>
      {renderRoomType()}
    </>
  );
};

export default HousingOverviewRoomType;
