import React from "react";

import {
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { format } from 'date-fns';

import RichTextEditor from "@shared/forms/RichTextEditor";

//https://stackoverflow.com/questions/73745743/cant-get-formik-form-to-work-with-material-ui
//This is stupid and I hate it.
//Please find a work around for this. Doing it inline either wont connect the name to the field,
//Or unselects after typing

const MuiTextField = ({ field, form, ...props }) => {
  return <TextField {...field} {...props} />;
};

const MuiTextFieldSlug = ({ field, form, ...props }) => {
  return <TextField {...field} {...props} />;
};


const MuiTextArea = ({ field, form, ...props }) => {
  return <TextField multiline={true} minRows={3} {...field} {...props} />;
}


const MuiCheckbox = ({ field, form, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox {...field} {...props} checked={field.value} />
      }
      label={props.label}
    />
  )
}

const MuiCheckboxForcedValue = ({ field, form, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          {...props}
          checked={field.value == props.checkValue}
          onChange={() => {
            form.setFieldValue(field.name, props.checkValue)
          }}
        />
      }
      label={props.label}
    />
  )
}


const getDateWithoutTimezone = (value) => {
  let temp_value = ""
  if(value){
    if(typeof value == "object") {
      temp_value = value.toISOString()
    } else {
      temp_value = value
    }
    return new Date(temp_value.split("T")[0]+" 00:00:00");
  }
  return null
}

//DEAR GOD HELP ME
//https://github.com/mui/mui-x/pull/8261/files
//https://mui.com/x/react-date-pickers/timezone/
//Adjust at own peril.
const MuiDatePicker = ({ field, form, ...props }) => {
  return <DatePicker
    {...field}
    {...props}
    format="yyyy-MM-dd"
    timezone="system"
    onChange={date => {
      field.onChange(
        format(date, 'yyyy-MM-dd')
      );
      form.setFieldValue(field.name, format(date, 'yyyy-MM-dd'))
    }}
    value={getDateWithoutTimezone(field.value)}//new Date(field.value) : null}
    slotProps={{
      textField: {
        size: "small",
        fullWidth: true
      },
      actionBar: {
        actions: ['clear']
      }
  }} />
}

const FormikRichTextEditor = ({ field, form, ...props }) => {
  return <RichTextEditor
    {...field}
    {...props}
    onChange={value => {
      field.onChange(
        value
      );
      form.setFieldValue(field.name, value)
    }}
    value={field.value}
  />
}

const MuiDateTimePicker = ({ field, form, ...props }) => {
  if(typeof field.value == "string"){
    field.value = Date.parse(field.value)
  }
  return <DateTimePicker
    {...field} {...props} slotProps={{
      textField: {...props},
      actionBar: {
        actions: ['clear']
      }
    }}
    onChange={date => {
      form.setFieldValue(field.name, date)
    }}

  />;
}

export {
  MuiTextField,
  MuiTextArea,
  MuiDatePicker,
  MuiDateTimePicker,
  MuiCheckbox,
  MuiCheckboxForcedValue,
  FormikRichTextEditor
};
