import React from "react";

import RouterTabbedPage from "@shared/RouterTabbedPage";

const Apis = () => {
  return (
    <RouterTabbedPage
      tabs={[
        { label: "Tokens", path: "/apis/tokens" },
        { label: "Integrations", path: "/apis/integrations" }
      ]}
    />
  );
};

export default Apis;
