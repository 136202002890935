import React, { useContext, useState } from "react";

import { useTheme } from "@mui/material/styles";
import compact from "lodash/compact";

import EventUserContext from "@event/EventUserContext";
import { renderCreateButton } from "@shared/FormUtils";

import HousingBlankHotel from "./HousingBlankHotel";
import HousingHotelModal from "./HousingHotelModal";

const HousingHotelsIndex = props => {
  const {
    hotels,
    goEdit,
    goRooms,
    setEditHotelId,
  } = props;
  const { user } = useContext(EventUserContext);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const muiTheme = useTheme();

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const renderActions = (hotel) => {
    let actions = [
      <span
        key={`hotel-manage-action-${hotel.id}`}
        className="inline-block cursor-pointer pr-2 uppercase text-ui-7"
        onClick={() => {
          setEditHotelId(hotel.id);
          goRooms();
        }}
      >
        Manage Rooms
      </span>
    ]
    if (user.role != "basic" || user.permission.housing_edit) {
      actions.push(
        <span
          key={`hotel-details-action-${hotel.id}`}
          className="inline-block cursor-pointer pl-2 uppercase text-ui-7 border-l border-l-ui-7"
          onClick={() => {
            setEditHotelId(hotel.id);
            goEdit();
          }}
        >
          Edit Details
        </span>
      );
    }

    return actions;
  }

  const renderHotelTable = () => {
    return (
      <div className="my-2">
        <table
          className="w-full table-fixed"
          style={{
            fontFamily: muiTheme.typography.fontFamily,
            fontSize: muiTheme.typography.fontSize
          }}
        >
          <thead>
            <tr>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Hotel Name
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Address
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Room Types
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-right text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {hotels.map(hotel => (
              <tr key={hotel.gid}>
                <td className="border-t px-4 py-4 font-bold">{hotel.name}</td>
                <td className="border-t px-4 py-4">
                  {hotel.address_street_1}
                  <br />
                  {compact([
                    hotel.address_city,
                    hotel.address_state,
                    hotel.address_country
                  ])
                    .filter(Boolean)
                    .join(", ")}
                </td>
                <td className="border-t px-4 py-4">
                  {hotel.room_types.length}
                </td>
                <td className="border-t px-4 py-4 text-right leading-none text-[13px] font-medium">
                  {renderActions(hotel)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderNewHotelButton = () => {
    return renderCreateButton("Create New Hotel", () => {
      setModalAddVisible(true);
    });
  };

  return (
    <div>
      {renderNewHotelButton()}
      {renderHotelTable()}
      <HousingHotelModal
        hotel={HousingBlankHotel}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Hotel"
      />
    </div>
  );
};

export default HousingHotelsIndex;
