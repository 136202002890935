import React from "react";

import PageHeader from "@shared/PageHeader";

import ApisIntegrationsMarketo from "./marketo/ApisIntegrationsMarketo";

const ApisIntegrations = () => {
  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <PageHeader text="API Integrations" />
      <ApisIntegrationsMarketo />
    </div>
  );
};

export default ApisIntegrations;
