import React, { useContext } from "react";

import { format, parseISO } from "date-fns";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import GrowlTable from "@shared/GrowlTable";

const ApisTokenTable = (props) => {
  const { setEdit, setView, tokens } = props;
  const { event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.configuration_api_edit) {
      return false;
    }
    return true;
  };

  const columns = [
    {
      headerName: "Token Name",
      field: "name",
      flex: 1
    },
    {
      headerName: "Token Value",
      field: "value",
      flex: 1
    },
    {
      headerName: "Expiration Date",
      field: "expiry",
      flex: 1,
      renderCell: (params) => format(parseISO(params.value), "y-MM-dd")
    },
    {
      headerName: "Permission Count",
      field: "permission_count",
      flex: 1,
      valueGetter: (_value, row) => Object.keys(row).filter((key) => row[key] === true).length
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 260,
      getActions: (params) => [renderViewAction(params.row), renderEditAction(params.row)]
    }
  ];

  const renderViewAction = (token) => {
    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => {
            setView(token.id);
          }}
        >
          View
        </div>
      </>
    );
  };

  const renderEditAction = (token) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => {
            setEdit(token.id);
          }}
        >
          Edit
        </div>
      </>
    );
  };

  const renderTable = () => <GrowlTable columns={columns} items={tokens} tableName={`${event.slug}-api-tokens`} />;

  if (tokens.length < 1) {
    return (
      <div>
        <p>No tokens have been generated.</p>
      </div>
    );
  }
  return renderTable();
};

export default ApisTokenTable;
