import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchMetadataFields = async (apiRoot, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/metadata/fields"),
    signal: signal
  });
};

const addMetadataField = async (apiRoot, data) => {
  return request({
    method: "post",
    url: urljoin(apiRoot, "/metadata/fields"),
    data: data
  });
};

const deleteMetadataField = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/metadata/fields/${id}`)
  });
};

const updateMetadataField = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/metadata/fields/${id}`),
    data: data
  });
};

const copyMetadataFieldsFromEvent = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, "/metadata/copy_event"),
    data: data
  });
};

const setMetadataFieldsOrder = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, "/metadata/fields/sort_order"),
    data: data
  });
};

export const useMetadataFields = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["metadataFields", eventId],
    queryFn: ({ signal }) => fetchMetadataFields(apiRoot, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useAddMetadataField = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addMetadataField(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["metadataFields", eventId]);
    }
  });
};

export const useCopyMetadataFieldsFromEvent = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => copyMetadataFieldsFromEvent(apiRoot, data),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["metadataFields", eventId] });
    }
  });
};

export const useDeleteMetadataField = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteMetadataField(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["metadataFields", eventId]);
    }
  });
};

export const useUpdateMetadataField = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateMetadataField(apiRoot, id, data),
    onMutate: async (field) => {
      await queryClient.cancelQueries({ queryKey: ["metadataFields", eventId] });
      const previousFields = queryClient.getQueryData(["metadataFields", eventId]);
      queryClient.setQueryData(["metadataFields", eventId], (old) => {
        const newFields = old.map((f) => (f.id === field.id ? { ...f, ...field.data.field } : f));
        return newFields;
      });
      return { previousFields };
    },
    onError: (_err, _field, context) => {
      queryClient.setQueryData(["metadataFields", eventId], context.previousFields);
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["metadataFields", eventId] });
    }
  });
};

export const useSetMetadataFieldsOrder = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => setMetadataFieldsOrder(apiRoot, data),
    onMutate: async (fields) => {
      await queryClient.cancelQueries({ queryKey: ["metadataFields", eventId] });
      const previousFields = queryClient.getQueryData(["metadataFields", eventId]);
      queryClient.setQueryData(["metadataFields", eventId], (_old) => {
        const newFields = fields.data.fields;
        return newFields;
      });
      return { previousFields };
    },
    onError: (_err, _field, context) => {
      queryClient.setQueryData(["metadataFields", eventId], context.previousFields);
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["metadataFields", eventId] });
    }
  });
};
