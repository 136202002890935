import React, { useContext, useState } from "react";

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Formik } from "formik";
import sortBy from "lodash/sortBy";

import EventContext from "@event/EventContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderCheckField, renderTextField } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import { useRefreshEventMetadata } from "@shared/hooks/useEventMetadata";
import { useSetMetadataFieldsOrder, useDeleteMetadataField, useUpdateMetadataField } from "@shared/hooks/useMetadata";
import { useRefreshParticipantsMeta } from "@shared/hooks/useParticipants";

import MetadataBlankField from "./MetadataBlankField";
import MetadataCopyEventModal from "./MetadataCopyEventModal";
import MetadataFieldForm from "./MetadataFieldForm";
import MetadataFieldOptionsNew from "./MetadataFieldOptionsNew";
import MetadataTableFieldDragContainer from "./MetadataTableFieldDragContainer";

const MetadataTable = (props) => {
  const { forceSetFields, fields, fieldType, label } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [fieldAdd, setFieldAdd] = useState(false);
  const [copyEvent, setCopyEvent] = useState(false);
  const [fieldBeingCloned, setFieldBeingCloned] = useState(null);
  const [fieldEdit, setFieldEdit] = useState({ id: null });
  const sensors = useSensors(useSensor(PointerSensor));
  const updateField = useUpdateMetadataField(apiRoot, event.id);
  const deleteField = useDeleteMetadataField(apiRoot, event.id);
  const setMetadataFieldsOrder = useSetMetadataFieldsOrder(apiRoot, event.id);
  const refreshEventMetadata = useRefreshEventMetadata(event.id);
  const refreshParticipantsMetadata = useRefreshParticipantsMeta(event.id);

  const sortFields = (fieldsToSort) => sortBy(fieldsToSort, ["sort_order"]);

  const revealAddFieldForm = () => {
    setFieldBeingCloned(null);
    setFieldAdd(true);
  };

  const revealCloneFieldForm = (field) => {
    setFieldBeingCloned(field);
    setFieldAdd(true);
  };

  const revealCopyMetadataModal = () => {
    setCopyEvent(true);
  };

  const hideCopyMetadataModal = () => {
    setCopyEvent(false);
  };

  const hideAddFieldForm = () => {
    setFieldBeingCloned(null);
    setFieldBeingCloned(null);
    setFieldAdd(false);
  };

  const addSuccess = () => {
    alertSuccess("Field saved successfully");
    setFieldAdd(false);
  };

  const copySuccess = (response) => {
    alertSuccess("Event copied successfully");
    forceSetFields(response.data.fields);
    setFieldAdd(false);
    hideCopyMetadataModal();
  };

  const addFailure = (response) => {
    alertError(`Failed adding field: ${response.data.error}`);
  };

  const copyFailure = (response) => {
    alertError(`Failed copying event: ${response.data.error}`);
  };

  const editField = (id) => {
    const field = fields.find((f) => f.id === id);
    if (field) {
      setFieldEdit(field);
    }
  };

  const resetEdit = () => {
    setFieldEdit({ id: null });
  };

  const removeField = (fieldId) => {
    const callbacks = {
      onSuccess: (data) => {
        if (data.error === null) {
          alertSuccess("Successfully deleted custom field");
        } else {
          alertError(`Unable to delete custom field: ${data.error}`);
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error) {
          alertError(error.response?.data?.error);
        } else {
          alertError(error);
        }
      }
    };

    deleteField.mutate({ id: fieldId, callbacks });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active != over) {
      const oldIndex = fields.findIndex((x) => x.id === active.id);
      const newIndex = fields.findIndex((x) => x.id === over.id);
      const newFields = arrayMove(fields, oldIndex, newIndex);

      const ids = newFields.map((x) => x.id);
      newFields.map((x, i) => {
        x.sort_order = i;
        return x;
      });

      const postData = { ids: ids };
      const callbacks = {
        onSuccess: (data) => {
          if (data.error === null) {
            refreshEventMetadata();
            refreshParticipantsMetadata();
          } else {
            alertError("Failed sorting custom fields");
          }
        },
        onError: (error) => {
          if (error?.response?.data?.error) {
            alertError(error.response?.data?.error);
          } else {
            alertError(error);
          }
        }
      };
      setMetadataFieldsOrder.mutate({ data: postData }, callbacks);
    }
  };

  const renderEditFieldLink = (field) => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        editField(field.id);
      }}
    >
      Edit
    </span>
  );

  const renderCloneFieldLink = (field) => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        revealCloneFieldForm(field);
      }}
    >
      Clone
    </span>
  );

  const renderDeleteFieldLink = (field) => (
    <GrowlModal
      content="Are you sure you want to delete this field?"
      title="Delete Field"
      trigger={<span className="sg-mgmt-link sg-mgmt-reg-field-type">Delete</span>}
      actions={[
        {
          label: "Cancel",
          close: true
        },
        {
          label: "Delete",
          close: true,
          func: () => {
            removeField(field.id);
          }
        }
      ]}
    />
  );

  const renderCancelFieldLink = () => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        resetEdit();
      }}
    >
      Cancel
    </span>
  );

  const renderUpdateFieldLink = (submitForm) => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        submitForm();
      }}
    >
      Save
    </span>
  );

  const renderRegField = (field) => {
    if (fieldType === "event_participant") {
      return <td className="border px-4 py-2 align-top text-sm">{field.registration ? "yes" : "no"}</td>;
    }
    return <></>;
  };

  const renderFieldInfo = (field) => (
    <>
      <td className="border px-4 py-2 align-top text-sm">{field.label}</td>
      <td className="border px-4 py-2 align-top text-sm">{field.slug}</td>
      <td className="border px-4 py-2 align-top text-sm">{field.field_format}</td>
      <td className="border px-4 py-2 align-top text-sm">
        {field.options.map((opt) => (
          <div key={opt.id}>{`${opt.slug}: ${opt.label}`}</div>
        ))}
      </td>
      <td className="border px-4 py-2 align-top text-sm">{field.sort_order}</td>
      {renderRegField(field)}
      <td className="border px-4 py-2 align-top text-sm">
        {renderEditFieldLink(field)}
        {renderCloneFieldLink(field)}
        {renderDeleteFieldLink(field)}
      </td>
    </>
  );

  const renderFormField = (name) => renderTextField("", `field[${name}]`);

  const renderOptionsField = (opt, idx) => {
    return (
      <div key={opt.id}>
        <div>{opt.slug}</div>
        <div>
          {renderTextField("Label (question)", `field[options][${idx}][label]`)}
          <input type="hidden" name={`field[options][${idx}][slug]`} value={opt.slug} />
          <input type="hidden" name={`field[options][${idx}][id]`} value={opt.id} />
        </div>
      </div>
    );
  };

  const renderRegistrationField = (field) => {
    if (field.field_type === "event_participant") {
      return (
        <td className="border px-4 py-2 align-top text-sm">
          {renderCheckField("Registration Question", "field[registration]")}
        </td>
      );
    }
    return <></>;
  };

  const renderFieldEdit = (field) => (
    <Formik
      key={`edit-field-${field.id}`}
      initialValues={{
        field: {
          label: field.label,
          slug: field.slug,
          sort_order: field.sort_order,
          options: field.options,
          registration: field.registration
        }
      }}
      onSubmit={(values, { setSubmitting }) => {
        const callbacks = {
          onSuccess: (data) => {
            if (data.error === null) {
              alertSuccess("Successfully updated custom field");
              resetEdit();
            } else {
              alertError(`Unable to update custom field: ${data.error}`);
            }
            setSubmitting(false);
          },
          onError: (error) => {
            if (error?.response?.data?.error) {
              alertError(error.response?.data?.error);
            } else {
              alertError(error);
            }
            setSubmitting(false);
          }
        };

        updateField.mutate({ id: field.id, data: values }, callbacks);
      }}
    >
      {({ submitForm }) => (
        <>
          <td className="border px-4 py-2 align-top text-sm">{renderFormField("label")}</td>
          <td className="border px-4 py-2 align-top text-sm">{renderFormField("slug")}</td>
          <td className="border px-4 py-2 align-top text-sm">{field.field_format}</td>
          <td className="border px-4 py-2 align-top text-sm">
            {field.options.map((opt, idx) => renderOptionsField(opt, idx))}
            <MetadataFieldOptionsNew field={field} />
          </td>
          <td className="border px-4 py-2 align-top text-sm">{renderFormField("sort_order")}</td>
          {renderRegistrationField(field)}
          <td className="border px-4 py-2 align-top text-sm">
            {renderCancelFieldLink()}
            {renderUpdateFieldLink(submitForm)}
          </td>
        </>
      )}
    </Formik>
  );

  const renderField = (field) => {
    return (
      <MetadataTableFieldDragContainer field={field} id={field.id} key={`field-${field.id}`}>
        {field.id === fieldEdit.id ? renderFieldEdit(field) : renderFieldInfo(field)}
      </MetadataTableFieldDragContainer>
    );
  };

  const renderFields = () => {
    const columns = ["Drag", "Label", "Slug", "Type", "Options", "Sort Order", "Actions"];
    if (fieldType === "event_participant") {
      columns.splice(6, 0, "Registration");
    }
    return (
      <form className="sg-mgmt-form" id="sg-mgmt-reg-field-edit">
        <table className="table-auto">
          <thead>
            <tr>
              {columns.map((col) => (
                <th key={col} className="border px-4 py-2">
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <tbody>
              <SortableContext items={fields.map((x) => x.id)} strategy={verticalListSortingStrategy}>
                {sortFields(fields).map((field) => renderField(field))}
              </SortableContext>
            </tbody>
          </DndContext>
        </table>
      </form>
    );
  };

  const renderNoFields = () => {
    if (fields.length === 0) {
      return <div className="p-1">No custom fields have been created yet.</div>;
    }
    return <></>;
  };

  const renderCopyMetadata = () => {
    if (!fieldAdd) {
      return (
        <div className="sg-mgmt-reg-field-add sg-mgmt-link" onClick={revealCopyMetadataModal}>
          Copy Custom Fields From Another Event
        </div>
      );
    }
    return <></>;
  };

  const renderAddField = () => {
    if (!fieldAdd) {
      return (
        <div className="sg-mgmt-reg-field-add sg-mgmt-link" onClick={revealAddFieldForm}>
          + Add Field
        </div>
      );
    }
    return <></>;
  };

  const getClonedBlankField = (field) => {
    //Deep copy. Yes I hate this too.
    const new_field = JSON.parse(JSON.stringify(field));
    new_field["id"] = null;
    new_field["event_field_options"] = field.options;
    return new_field;
  };

  const renderAddFieldForm = () => {
    if (fieldAdd) {
      return (
        <div className="sg-mgmt-reg-field-form-container">
          <MetadataFieldForm
            key={`metadate-field-form-${fieldBeingCloned ? fieldBeingCloned.id : "new"}`}
            field={fieldBeingCloned ? getClonedBlankField(fieldBeingCloned) : MetadataBlankField}
            fieldType={fieldType}
            callbackFailure={addFailure}
            callbackSuccess={addSuccess}
            cancelButton={hideAddFieldForm}
          />
        </div>
      );
    }
    return <></>;
  };

  const renderCopyMetadataModal = () => {
    return (
      <MetadataCopyEventModal
        fieldType={fieldType}
        modalVisible={copyEvent}
        callbackFailure={copyFailure}
        callbackSuccess={copySuccess}
        closeModal={hideCopyMetadataModal}
        cancelButton={hideCopyMetadataModal}
      />
    );
  };

  return (
    <div className="pb-8">
      <div className="mb-1">
        <h2>{label}</h2>
      </div>
      {renderFields()}
      {renderNoFields()}
      {renderAddFieldForm()}
      {renderAddField()}
      {renderCopyMetadata()}
      {renderCopyMetadataModal()}
    </div>
  );
};

export default MetadataTable;
