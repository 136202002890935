import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchHousingBookings = async (apiRoot) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, "/housing/bookings")
  });
};

const cancelHousingBooking = async (apiRoot, id) => {
  return request({
    method: "PUT",
    url: urljoin(apiRoot, `/housing/bookings/${id}/cancel`),
  });
};

const deleteHousingBooking = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/housing/bookings/${id}`),
  });
};

const finalizeHousingBookings = async (apiRoot, ids) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/bookings/finalize`),
    data: { ids }
  });
};

const addHousingBooking = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/housing/bookings`),
    data: data
  });
};

const updateHousingBooking = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/bookings/${id}`),
    data: data
  });
};

export const useHousingBookings = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["housingBookings", eventId],
    queryFn: () => fetchHousingBookings(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useCancelHousingBooking = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => cancelHousingBooking(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBookings", eventId]);
    }
  });
};

export const useAddHousingBooking = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addHousingBooking(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBookings", eventId]);
    }
  });
};

export const useDeleteHousingBooking = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteHousingBooking(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBookings", eventId]);
    }
  });
};

export const useFinalizeHousingBookings = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ ids }) => finalizeHousingBookings(apiRoot, ids),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBookings", eventId]);
    }
  });
};

export const useUpdateHousingBooking = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateHousingBooking(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBookings", eventId]);
    }
  });
};
