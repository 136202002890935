export default {
  name: "",
  feature_flag_view: false,
  feature_flag_edit: false,
  export_participants: false,
  export_sessions: false,
  export_surveys: false,
  forms_view: false,
  forms_edit: false,
  meetings_view: false,
  meetings_edit: false,
  participants_view: false,
  participants_edit: false,
  sessions_view: false,
  sessions_edit: false,
  surveys_view: false,
  surveys_edit: false,
  users_view: false,
  users_edit: false,
  expiry: new Date(),
  whitelist: [],
  whitelist_values: ""
};
