import React, { useContext, useEffect, useState } from "react";

import { Stack, Chip } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import { contentTypes, renderContentByType, getContentType } from "@contents/ContentTypes";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import InputSlugGenerator from "@shared/forms/InputSlugGenerator";
import {
  renderCancelButton,
  renderSubmitButton,
  renderTextField,
  renderRegexField,
  renderSelectField,
  renderButton
} from "@shared/FormUtils";
import Loading from "@shared/Loading";

const WebPageForm = (props) => {
  const { webPageId, cancel, callbackFailure, callbackSuccess, templateId } = props;
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [webPage, setWebPage] = useState(null);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchWebPage = async () => {
      const result = await axios(urljoin(apiRoot, `/contents/web_pages/${webPageId ? webPageId : "new"}`));
      setWebPage(result.data.web_page);
      setSelectedTags(result.data.web_page.tags);
    };

    const fetchTemplates = async () => {
      const result = await axios(urljoin(apiRoot, "/contents/web_page_templates"));
      setTemplates(result.data.web_page_templates);
    };

    const fetchTags = async () => {
      const result = await axios(urljoin(apiRoot, "/contents/web_page_tags"));
      setTags(result.data.web_page_tags);
    };

    fetchWebPage();
    fetchTags();
    fetchTemplates();
  }, [apiRoot, webPageId]);

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.content_web_pages_edit) {
      return false;
    }
    return true;
  };

  const formInitialValues = () => {
    const initialValues = {
      name: webPage ? webPage.name : "",
      slug: webPage ? webPage.slug : "",
      data: webPage ? webPage.data : {},
      content_type: webPage ? webPage.content_type : "",
      contents_web_page_template_id: webPage ? webPage.contents_web_page_template_id : null
    };
    if (templateId) {
      initialValues.contents_web_page_template_id = templateId;
      initialValues.content_type = "template";
    }
    return initialValues;
  };

  const formConfig = {
    formUrl: urljoin(apiRoot, `/contents/web_pages/${(webPage || {}).id}`),
    method: "PATCH"
  };

  const renderFormFields = (formikProps) => {
    const { values } = formikProps;
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-1/2">{renderTextField("Name", "web_page[name]", [], false, true)}</div>
            <div className="mr-4 w-1/2">
              {renderRegexField(
                "Identifier (lowercase letters, numbers, hyphens only)",
                "web_page[slug]",
                RegExp("^[0-9A-Za-z_-]{0,32}$"),
                [],
                {
                  required: true,
                  fieldProperties: {
                    InputProps: {
                      endAdornment: values.web_page.slug ? (
                        ""
                      ) : (
                        <InputSlugGenerator
                          formikProps={formikProps}
                          name={`web_page[slug]`}
                          associatedValue={values.web_page.name}
                          maxSlugLength={30}
                        />
                      )
                    }
                  }
                }
              )}
            </div>
          </div>
          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Content Type",
                "web_page[content_type]",
                contentTypes.map((x) => {
                  return {
                    value: x.slug,
                    label: x.name
                  };
                }),
                [],
                [],
                false,
                {}
              )}
            </div>
          </div>
          {renderTemplates(formikProps)}
          {renderTags(formikProps.values)}
          {renderContent(formikProps)}
        </div>
      </div>
    );
  };

  const renderTemplates = (formikProps) => {
    const { values } = formikProps;
    let contentType = getContentType(values.web_page.content_type);
    if (contentType && contentType.use_template && templates) {
      return (
        <div className="flex">
          <div className="mr-4 w-full">
            {renderSelectField(
              "Template",
              "web_page[contents_web_page_template_id]",
              templates.map((x) => {
                return {
                  value: x.id,
                  label: x.name
                };
              }),
              [],
              [],
              false,
              {}
            )}
          </div>
        </div>
      );
    }
  };

  const renderTemplateContent = (formikProps) => {
    const { values } = formikProps;
    const { web_page } = values;
    const template = templates.find((x) => x.id == web_page.contents_web_page_template_id);
    if (template) {
      const sortedQuestions = template.web_page_template_questions.sort((a, b) => a.sort_order - b.sort_order);
      const renderedQuestions = sortedQuestions.map((question) =>
        renderContentByType(formikProps, question.content_type, `data[${question.gid}]`, question.name, {})
      );
      return renderedQuestions;
    } else {
      return (
        <div className="w-full py-4">
          <div className="flex">
            <div className="mr-4 w-full">The selected template could not be found.</div>
          </div>
        </div>
      );
    }
  };

  const renderContent = (formikProps) => {
    const { values } = formikProps;
    const { web_page } = values;
    const contentType = getContentType(web_page.content_type);
    if (contentType && contentType.use_template) {
      return renderTemplateContent(formikProps);
    }
    if (web_page.content_type) {
      return (
        <div className="w-full py-4">
          <div className="flex">
            <div className="mr-4 w-full">
              {renderContentByType(formikProps, values.web_page.content_type, "content", "Content", {})}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderTags = (values) => {
    return (
      <div className="w-full py-4">
        <div className="sg-mgmt-form-input-container sg-mgmt-form-input-slug mb-0">
          <label>Tags</label>
        </div>

        {renderTagSelect(values)}
        {renderSelectedTags()}
      </div>
    );
  };

  const renderSelectedTags = () => {
    return (
      <div className="mt-2 flex">
        {selectedTags.map((tag) => {
          return (
            <Chip key={tag.gid} className="mr-2" color="primary" label={tag.name} onDelete={() => removeTag(tag.gid)} />
          );
        })}
      </div>
    );
  };

  const removeTag = (gid) => {
    setSelectedTags(selectedTags.filter((tag) => tag.gid !== gid));
  };

  const renderTagSelect = (values) => {
    const filteredTags = tags.filter((t) => !selectedTags.map((tag) => tag.gid).includes(t.gid));
    const options = filteredTags.map((opt) => ({
      label: opt.name,
      value: opt.gid,
      gid: opt.gid
    }));
    return (
      <Stack direction="row" spacing={2}>
        {renderSelectField("", "tag_add", options, [], [])}
        {editEnabled() && renderTagAddButton(values)}
      </Stack>
    );
  };

  const renderTagAddButton = (values) => {
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return renderButton("Add", () => addTag(tag_add), {
      disabled: disabled
    });
  };

  const addTag = (gid) => {
    // ignore missing tag
    if (!gid) {
      return;
    }

    // ignore duplicate tag
    if (selectedTags.map((t) => t.gid).indexOf(gid) != -1) {
      return;
    }

    const tag = tags.find((t) => t.gid === gid);
    setSelectedTags([tag].concat(selectedTags));
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          web_page: formInitialValues(),
          content: webPage.data ? webPage.data.content || "" : "",
          data: webPage.data || {}
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!editEnabled()) {
            alertError("Unauthorized");
            setSubmitting(false);
            return;
          }

          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          let submittingValues = {
            web_page: values.web_page
          };
          if (!(getContentType(values.web_page.content_type) || {}).use_template) {
            submittingValues.web_page.contents_web_page_template_id = null;
            submittingValues.web_page.data = { content: values.content };
          } else {
            submittingValues.web_page.data = values.data;
          }

          submittingValues.web_page.tags = selectedTags.map((t) => t.name);
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: submittingValues
          })
            .then((response) => {
              if (!response.data.error) {
                callbackSuccess ? callbackSuccess(response) : () => { };
                alertSuccess("Web Page saved");
                setSubmitting(false);
              } else {
                callbackFailure ? callbackFailure(response) : () => { };
                alertError(`Unable to save web page: ${response.data.error}`);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (_formikProps) => {
    return (
      <Stack direction="row" spacing={2}>
        {editEnabled() && renderSubmitButton("Save", false, {})}
        {renderCancelButton("Cancel", cancel)}
      </Stack>
    );
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <h1>
        {webPageId ? "Edit " : "Add "}
        {webPage && webPage.name ? webPage.name : "Web Page"}
      </h1>
      {webPage && webPage.id != null ? renderForm() : <Loading />}
    </div>
  );
};

export default WebPageForm;
