import React, { useContext, useEffect } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError } from "@shared/Alerts";
import { renderTextField , renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { useDropzoneField } from "@shared/hooks/useDropzoneField";

import SessionsBlankVenue from "./SessionsBlankVenue";

const SessionsVenueForm = (props) => {
  const {
    callbackFailure = () => {},
    callbackSuccess = () => {},
    cancelButton = () => {},
    venue
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const { file, setPreviewImageURL, renderImagePreview, renderFileUpload } = useDropzoneField({
    fieldName: "venue[image]",
    acceptedFileTypes: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/pjpeg"
    ]
  });

  useEffect(() => {
    setPreviewImageURL(venue.image_url);
  }, [venue, setPreviewImageURL]);

  const isEdit = () => {
    return venue && venue.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/venues", `/${venue.id}`),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit Field"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/venues"),
      method: "POST",
      saveButton: "Create",
      title: "Create Field"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: venue.name || "",
        address_street_1: venue.address_street_1 || "",
        address_street_2: venue.address_street_2 || "",
        address_city: venue.address_city || "",
        address_state: venue.address_state || "",
        address_postcode: venue.address_postcode || "",
        address_country: venue.address_country || "",
        phone: venue.phone || ""
      };
    }
    return SessionsBlankVenue;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          venue: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          if (file) {
            formData.set("venue[image]", file);
          }

          if (!values.venue.name) {
            alertError("Venue name is required");
            setSubmitting(false);
            return;
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Venue Name", "venue[name]", [], false, true)}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Address (line 1)", "venue[address_street_1]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Address (line 2)", "venue[address_street_2]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("City", "venue[address_city]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("State/Province", "venue[address_state]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("ZIP/Postal Code", "venue[address_postcode]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Country", "venue[address_country]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Phone Number", "venue[phone]")}
              </div>
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-input-container">
                  <label>Choose venue image</label>
                  {renderFileUpload()}
                  {renderImagePreview()}
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting, {
                type: "submit",
                color: "secondary"
              })}
              {renderCancelButton("Cancel", cancelButton, {
                color: "secondary"
              })}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default SessionsVenueForm;
