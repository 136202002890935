import React from "react";

import Modal from "react-modal";

import FeaturesForm from "./FeaturesForm";

const FeaturesFormModal = (props) => {
  const { featureId, modalVisible, resetModal, callbackFailure, callbackSuccess } = props;

  Modal.setAppElement("#root");

  const cancelButton = () => {
    resetModal();
  };

  const renderFeatureForm = () => {
    return (
      <FeaturesForm
        cancel={cancelButton}
        callbackSuccess={callbackSuccess}
        callbackFailure={callbackFailure}
        featureId={featureId}
      />
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Edit Feature"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">{modalVisible ? renderFeatureForm() : ""}</div>
        </div>
      </div>
    </Modal>
  );
};

export default FeaturesFormModal;
