import React, { useEffect, useState } from "react";

import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import Modal from "react-modal";
import urljoin from "url-join";

import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import {
  renderCheckField,
  renderTextField,
  renderDateField,
  renderCancelButton,
  renderSubmitButton
} from "@shared/FormUtils";
import Loading from "@shared/Loading";

const CheckinPanelModal = (props) => {
  const { apiRoot, modalVisible, metadataFields, participantId = null, resetModal, updateFunc } = props;
  const [fetched, setFetched] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [dataEditor, setDataEditor] = useState(false);
  const [dataEditorRaw, setDataEditorRaw] = useState(false);

  useEffect(() => {
    const fetchParticipant = async () => {
      if (!participantId) {
        setFetched(true);
        return;
      }
      try {
        const result = await axios(urljoin(apiRoot, "/participants", `/${participantId}`));
        setParticipant(result.data.participant);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchParticipant();
  }, [apiRoot, participantId]);

  Modal.setAppElement("#root");

  const isEdit = () => {
    return participant && participant.id;
  };

  const config = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-participant-edit",
        formUrl: urljoin(apiRoot, "/participants", `/${participant.id}`),
        method: "PATCH",
        title: "Check in Participant"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-participant-add",
      formUrl: urljoin(apiRoot, "/participants"),
      method: "POST",
      title: "Add Participant"
    };
  })();

  const renderCustomTextField = (field, metaType = "custom", label = "", formatClass = [], disabled) => {
    return renderTextField(label, `participant[${metaType}][${field.slug}]`, formatClass, disabled, false, {});
  };

  const renderCustomDateField = (field, metaType = "custom", label = "") => {
    return renderDateField(label, `participant[${metaType}][${field.slug}]`, []);
  };

  const renderCustomDropdownField = (field, metaType = "custom", label = "", formatClasses = {}) => {
    const options = field.options.map((opt) => ({
      label: opt.label,
      value: opt.label
    }));

    return (
      <SelectField
        label={label}
        name={`participant[${metaType}][${field.slug}]`}
        options={options}
        formatClasses={formatClasses}
        includeBlank={true}
      />
    );
  };

  const renderCustomCheckField = (field, metaType = "custom", label = "") => {
    const options = field.options.map((opt) => ({
      id: opt.id,
      label: opt.label,
      slug: opt.slug
    }));
    return (
      <>
        {options.map((opt) => (
          <td className="px-2" key={`checkin-panel-checkbox-${opt.slug}`}>
            {renderCheckField(label, `participant[${metaType}][${field.slug}][options][${opt.slug}]`, ["text-center"])}
          </td>
        ))}
      </>
    );
  };

  /* eslint-disable dot-notation */
  const initialCustomAnswers = () => {
    if (participant && participant.registration_answers) {
      const custom = {};
      const ids = Object.keys(participant.registration_answers);
      ids.forEach((id) => {
        if (isPlainObject(participant.registration_answers[id])) {
          const val = { options: {} };
          const keys = Object.keys(participant.registration_answers[id]);
          keys.forEach((key) => {
            val["options"][key] = true;
          });
          custom[id] = val;
        } else if (isArray(participant.registration_answers[id])) {
          const val = { options: {} };
          participant.registration_answers[id].forEach((opt) => {
            val["options"][opt] = true;
          });
          custom[id] = val;
        } else {
          custom[id] = participant.registration_answers[id];
        }
      });

      return custom;
    }
    return {};
  };
  /* eslint-enable dot-notation */

  const renderMetadataField = (field, label = "", formatClass = [], disabled = false) => {
    switch (field.field_format) {
      case "text":
        return renderCustomTextField(field, "metadata", label, formatClass, disabled);
      case "date":
        return renderCustomDateField(field, "metadata", label);
      case "dropdown":
        return renderCustomDropdownField(field, "metadata", label);
      case "checkboxes":
        return renderCustomCheckField(field, "metadata", label);
      default:
        return <div key={field.gid}>not a text field</div>;
    }
  };

  const initialMetadataValues = () => {
    const metadataValues = {};
    participant.metadata.forEach((md) => {
      if (md.field_format == "checkboxes") {
        const val = { options: {} };
        md.value.split(",").forEach((opt) => {
          val["options"][opt] = true;
        });
        metadataValues[md.field_slug] = val;
      } else {
        metadataValues[md.field_slug] = md.value;
      }
    });
    return metadataValues;
  };

  const copyToClipboard = (value) => {
    alertSuccess("Copied value to clipboard");
    navigator.clipboard.writeText(value);
  };

  const renderIndividualField = (label, value, copyable = false) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
        {copyable ? (
          <>
            &nbsp;
            <ContentCopyIcon
              className="cursor-pointer"
              onClick={() => copyToClipboard(value)}
              fontSize="small"
              color="primary"
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderIndividual = () => {
    if (isEdit()) {
      return (
        <>
          {renderIndividualField("First Name", participant.individual.name_first)}
          {renderIndividualField("Last Name", participant.individual.name_last)}
          {renderIndividualField("Company", participant.individual.company)}
          {renderIndividualField("Email", participant.individual.email, true)}
          {renderIndividualField("Participant Type", participant.type)}
        </>
      );
    }
  };

  const formInitialValues = () => {
    if (participant) {
      return {
        individual_gid: "",
        role: participant.role || "attendee",
        status: participant.status || "preregistered",
        override_name_first: participant.override_name_first || "",
        override_name_last: participant.override_name_last || "",
        override_company: participant.override_company || "",
        override_email: participant.override_email || "",
        event_participant_type_id: participant.event_participant_type_id || "",
        data: participant.data || {},
        custom: initialCustomAnswers(),
        metadata: initialMetadataValues()
      };
    }
  };

  const dataBlockInitialValues = () => {
    const dataObj = {};
    if (participant) {
      Object.keys(participant.data).forEach((item, idx) => {
        dataObj[idx] = {
          key: item,
          value: participant.data[item]
        };
      });
    }
    return dataObj;
  };

  const renderButtons = (isSubmitting) => (
    <Stack direction="row" spacing={2}>
      {renderSubmitButton("Save", isSubmitting)}
      {renderCancelButton("Cancel", resetModal)}
    </Stack>
  );

  const getAssignedTickets = (name) => {
    let count = 0;
    participant.ticketing_participant_tickets.forEach((pt) => {
      if (pt.ticket_type.name === name) count = count + 1;
    });
    return count;
  };

  // const isGuestEnabled = (guest_slug) => {
  //   const guestName = participant.metadata.find(md => md.field_slug === guest_slug)
  //   if (guestName && guestName.value !== '') {
  //     return true;
  //   }
  //   return false;
  // }

  const renderTicketBlocks = () => {
    const ticketBlocks = [
      {
        name: "Customer",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday"), meta_slug: "c-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday"), meta_slug: "c-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday"), meta_slug: "c-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday"), meta_slug: "c-tt-sunday" }
        ]
      },
      {
        name: "Cisco Account Manager",
        values: [
          {
            name: "Thursday",
            count: getAssignedTickets("Thursday Cisco Account Manager"),
            meta_slug: "am-tt-thursday"
          },
          { name: "Friday", count: getAssignedTickets("Friday Cisco Account Manager"), meta_slug: "am-tt-friday" },
          {
            name: "Saturday",
            count: getAssignedTickets("Saturday Cisco Account Manager"),
            meta_slug: "am-tt-saturday"
          },
          { name: "Sunday", count: getAssignedTickets("Sunday Cisco Account Manager"), meta_slug: "am-tt-sunday" }
        ]
      },
      {
        name: "Host",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday Hosts"), meta_slug: "h-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday Hosts"), meta_slug: "h-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday Hosts"), meta_slug: "h-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday Hosts"), meta_slug: "h-tt-sunday" }
        ]
      },
      {
        name: "LCC",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday LCC"), meta_slug: "lcc-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday LCC"), meta_slug: "lcc-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday LCC"), meta_slug: "lcc-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday LCC"), meta_slug: "lcc-tt-sunday" }
        ]
      },
      {
        name: "Sponsorship",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday Sponsorship"), meta_slug: "s-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday Sponsorship"), meta_slug: "s-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday Sponsorship"), meta_slug: "s-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday Sponsorship"), meta_slug: "s-tt-sunday" }
        ]
      },
      {
        name: "Back Pocket",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday Back Pocket"), meta_slug: "bp-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday Back Pocket"), meta_slug: "bp-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday Back Pocket"), meta_slug: "bp-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday Back Pocket"), meta_slug: "bp-tt-sunday" }
        ]
      },
      {
        name: "CHR",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday CHR"), meta_slug: "chr-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday CHR"), meta_slug: "chr-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday CHR"), meta_slug: "chr-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday CHR"), meta_slug: "chr-tt-sunday" }
        ]
      },
      {
        name: "Talent",
        values: [
          { name: "Thursday", count: getAssignedTickets("Thursday Talent"), meta_slug: "t-tt-thursday" },
          { name: "Friday", count: getAssignedTickets("Friday Talent"), meta_slug: "t-tt-friday" },
          { name: "Saturday", count: getAssignedTickets("Saturday Talent"), meta_slug: "t-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday Talent"), meta_slug: "t-tt-sunday" }
        ]
      },
      {
        name: "Donation",
        values: [
          { name: "Thursday", empty: true },
          { name: "Friday", empty: true },
          { name: "Saturday", count: getAssignedTickets("Saturday Donation"), meta_slug: "d-tt-saturday" },
          { name: "Sunday", count: getAssignedTickets("Sunday Donation"), meta_slug: "d-tt-sunday" }
        ]
      }
    ];
    return ticketBlocks.map((tb) => {
      if (tb.values.some((v) => v.count > 0)) {
        return renderTicketBlock(tb);
      }
      return <></>;
    });
  };

  const renderTicketBlock = (tb) => {
    return (
      <>
        <h2 className="pt-2">{tb.name}</h2>
        <table className="w-full">
          <thead>
            <tr>
              <th className="w-40"></th>
              {tb.values.map((t) => (
                <th className="w-36 px-2 text-center" key={`checkin-panel-name-${t.name}`}>
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Allocated</td>
              {tb.values.map((t, index) => {
                if (t.empty) {
                  return <td key={`checkin-panel-count-${index}`} />;
                } else {
                  return (
                    <td className="px-2 text-center" key={`checkin-panel-count-${t.name}`}>
                      {t.count}
                    </td>
                  );
                }
              })}
            </tr>
            <tr>
              <td className="pb-4">Transferred</td>
              {tb.values.map((t, index) => {
                if (t.empty) {
                  return <td key={`checkin-panel-transferred-${index}`} />;
                } else {
                  return (
                    <td className="px-2" key={`checkin-panel-transferred-${t.name}`}>
                      {renderMetadataField(
                        metadataFields.find((mf) => mf.slug === t.meta_slug),
                        "",
                        ["checkin-panel-center"]
                      )}
                    </td>
                  );
                }
              })}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const getNumberOfGuests = () => {
    let ticketCounts = {};
    let maxRepeatedTickets = 0;
    participant.ticketing_participant_tickets.forEach((pt) => {
      if (ticketCounts[pt.ticketing_ticket_type_id]) {
        ticketCounts[pt.ticketing_ticket_type_id] = ticketCounts[pt.ticketing_ticket_type_id] + 1;
      } else {
        ticketCounts[pt.ticketing_ticket_type_id] = 1;
      }
    });
    Object.keys(ticketCounts).forEach((key) => {
      if (ticketCounts[key] > maxRepeatedTickets) maxRepeatedTickets = ticketCounts[key];
    });
    return maxRepeatedTickets - 1;
  };

  const renderCheckinBlocks = () => {
    const guestCount = getNumberOfGuests();
    const checkinBlocks = [
      {
        name: "Main Participant",
        hotel_meta_slug: "mp-h-c",
        on_course_meta_slug: "mp-oc-c",
        values: [{ name: "Thursday" }, { name: "Friday" }, { name: "Saturday" }, { name: "Sunday" }]
      }
    ];
    if (guestCount > 0) {
      checkinBlocks.push({
        name: "Guest 1",
        guest_slug: "guest-name",
        hotel_meta_slug: "g1-h-c",
        on_course_meta_slug: "g1-oc-c",
        values: [{ name: "Thursday" }, { name: "Friday" }, { name: "Saturday" }, { name: "Sunday" }]
      });
    }
    if (guestCount > 1) {
      checkinBlocks.push({
        name: "Guest 2",
        guest_slug: "guest-2-name",
        hotel_meta_slug: "g2-h-c",
        on_course_meta_slug: "g2-oc-c",
        values: [{ name: "Thursday" }, { name: "Friday" }, { name: "Saturday" }, { name: "Sunday" }]
      });
    }
    if (guestCount > 2) {
      checkinBlocks.push({
        name: "Guest 3",
        guest_slug: "guest-3-name",
        hotel_meta_slug: "g3-h-c",
        on_course_meta_slug: "g3-oc-c",
        values: [{ name: "Thursday" }, { name: "Friday" }, { name: "Saturday" }, { name: "Sunday" }]
      });
    }
    return checkinBlocks.map((cb) => renderCheckinBlock(cb));
  };

  const renderCheckinBlock = (cb) => {
    return (
      <>
        <h2 className="pt-2">{cb.name}</h2>
        {cb.guest_slug ? (
          renderMetadataField(
            metadataFields.find((mf) => mf.slug === cb.guest_slug),
            "Guest Name"
          )
        ) : (
          <></>
        )}
        <table className="w-full">
          <thead>
            <tr>
              <th className="w-40"></th>
              {cb.values.map((t) => (
                <th className="w-36 text-center" key={`checkin-panel-guest-name-${t.name}`}>
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pb-4">Hotel Check In</td>
              {renderMetadataField(
                metadataFields.find((mf) => mf.slug === cb.hotel_meta_slug),
                "",
                ["text-center"]
              )}
            </tr>
            <tr>
              <td className="pb-4">On Course Check In</td>
              {renderMetadataField(
                metadataFields.find((mf) => mf.slug === cb.on_course_meta_slug),
                "",
                ["text-center"]
              )}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderNotes = () => {
    return (
      <>
        {renderMetadataField(
          metadataFields.find((mf) => mf.slug === "checkin-notes"),
          "Check in Notes"
        )}
        {renderMetadataField(
          metadataFields.find((mf) => mf.slug === "additional-notes"),
          "Additional Notes (for reference only)",
          [],
          true
        )}
      </>
    );
  };

  const performSubmit = (values, setSubmitting) => {
    const form = document.getElementById(config.formId);
    const formData = new FormData(form);
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // Metadata checkboxes values if all checkboxes are empty.
    if (values && values.participant.metadata) {
      const objectFieldsKeys = Object.keys(values.participant.metadata).filter(
        (key) => typeof values.participant.metadata[key] === "object"
      );
      objectFieldsKeys.forEach((key) => {
        const value = values.participant.metadata[key];
        if (value && value.options && Object.keys(value.options).filter((key) => value.options[key]).length == 0) {
          formData.set(`participant[metadata][${key}]`, "");
        }
      });
    }
    // Add participant GID on edits
    if (isEdit()) {
      formData.set("individual_gid", participant.individual.gid);
      formData.set("participant_gid", participant.gid);
    }

    // Construct data block from data_block values
    if (dataEditor) {
      if (!dataEditorRaw) {
        // table editor
        const newData = {};
        Object.keys(values.data_block).forEach((idx) => {
          const { key } = values.data_block[idx];
          const val = values.data_block[idx].value === undefined ? "" : values.data_block[idx].value;
          if (key) {
            newData[key] = val;
          }
          formData.delete(`data_block[${idx}][key]`);
          formData.delete(`data_block[${idx}][value]`);
        });
        Object.keys(newData).forEach((key) => {
          formData.set(`participant[data][${key}]`, newData[key]);
        });
      } else {
        // raw editor
        let formJson = {};
        try {
          formJson = JSON.parse(values.data_block_raw);
        } catch {
          alertError("JSON in data block editor is invalid");
          setSubmitting(false);
          return;
        }
        const newData = {};
        Object.keys(formJson).forEach((key) => {
          const val = formJson[key];
          newData[key] = val;
        });
        Object.keys(newData).forEach((key) => {
          formData.set(`participant[data][${key}]`, newData[key]);
        });
        formData.delete("data_block_raw");
      }
    }

    axios({
      url: config.formUrl,
      method: config.method,
      data: formData
    })
      .then((response) => {
        if (response.data.error === null) {
          updateFunc(response.data.participant);
          setDataEditor(false);
          setDataEditorRaw(false);
          resetModal();
          alertSuccess(`Participant ${config.alert} successfully`);
        } else {
          alertError(response.data.error);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        alertError(error);
        setSubmitting(false);
      });
  };

  const attemptSubmit = (values, setSubmitting) => {
    performSubmit(values, setSubmitting);
  };

  const renderAddEditParticipantModal = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-modal-frame">
          <div className="sg-mgmt-modal-title">{config.title}</div>
          <div className="sg-mgmt-modal-content">
            <Formik
              initialValues={{
                individual_gid: "",
                participant_gid: "",
                participant: formInitialValues(),
                data_block: dataBlockInitialValues()
              }}
              onSubmit={(values, { setSubmitting }) => {
                attemptSubmit(values, setSubmitting);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="sg-mgmt-form" id={config.formId}>
                  <div className="float-right -mt-2">{renderButtons(isSubmitting)}</div>
                  <div className="sg-mgmt-form-container">
                    <h2>Individual</h2>
                    {renderIndividual()}
                    {renderTicketBlocks()}
                    {renderCheckinBlocks()}
                    {renderNotes()}
                  </div>
                  <div className="sg-mgmt-form-actions">{renderButtons(isSubmitting)}</div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    }
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Participant:</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <Loading />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        className="sg-mgmt-modal"
        overlayClassName="sg-mgmt-modal-overlay"
        isOpen={modalVisible}
        onRequestClose={resetModal}
        contentLabel="Add Participant"
      >
        {renderAddEditParticipantModal()}
      </Modal>
    </>
  );
};

export default CheckinPanelModal;
