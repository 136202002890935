import React, { useContext, useEffect } from "react";

import { useNavigate } from "react-router";

import EventUserContext from "@event/EventUserContext";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const PeopleEventParticipants = () => {
  const { user } = useContext(EventUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === "basic" && !user.permission.participants_view) {
      navigate("/");
    }
  }, [user, navigate]);

  const getTabs = () => {
    let tabs = [
      {
        label: "Participants",
        path: "/participants"
      }
    ];

    if (user && (user.role != "basic" || user.permission.participants_edit)) {
      tabs.push({
        label: "Settings",
        path: "/participants/settings",
        icon: TabSettingsIcon
      });
    }
    return tabs;
  };

  return <RouterTabbedPage tabs={getTabs()} />;
};

export default PeopleEventParticipants;
