import React from "react";

import { Field } from "formik";
import { AutoNumericTextField } from "material-ui-autonumeric";

const CurrencyField = ({ fieldName, symbol = "$", disabled = false }) => {
  return (
    <Field name={fieldName}>
      {({ field }) => (
        <AutoNumericTextField
          props={{
            name: field.name,
            value: field.value,
            variant: "outlined",
            size: "small",
            onChange: field.onChange,
            disabled: disabled,
            // for some stupid ass reason, having an onBlur makes ALL fields unformat
            // when unfocusing from ONE element, even though onBlur only fires for the
            // one element. Possibly a Formik/controlled input issue but it is stupid
            // as fuck. Attempts to avoid the behavior have not yet succeeded. Removing
            // onBlur makes it so that selected fields don't format until re-hover, but
            // at least the other fields don't get all unformatted for no fucking reason.
            // onBlur: (evt) => {
            //   const val = AutoNumeric.getFormatted(`input[name="${fieldName}"]`);
            //   setFieldValue(fieldName, val);
            // }
          }}
          autoNumericOptions={{
            currencySymbol: `${symbol}`,
            decimalCharacter: ".",
            digitGroupSeparator: ",",
            minimumValue: "0.00",
            unformatOnHover: false,
            unformatOnSubmit: true
          }}
        />
      )}
    </Field>
  );
};

export default CurrencyField;
