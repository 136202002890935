import React from "react";

import clsx from "clsx";
import Modal from "react-modal";

import UsersForm from "./UsersForm";

const UsersModal = (props) => {
  const { eventUsers = [], modalVisible, resetModal, title, updateFunc, user, users = [] } = props;

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="w-[580px]">
        <div className={clsx("px-6 py-2 text-2xl leading-9 text-white", "bg-sg-yellow", user?.id && "bg-blue")}>
          {title}
        </div>
        <div className="sg-mgmt-modal-content">
          <UsersForm eventUsers={eventUsers} reset={resetModal} update={updateFunc} user={user} users={users} />
        </div>
      </div>
    </Modal>
  );
};

export default UsersModal;
