import React, { useContext, useEffect, useState } from "react";

import { Link } from "@mui/material";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTime } from "@shared/TimeUtils";

import ReplaceStaticFileModal from "./ReplaceStaticFileModal";
import UploadStaticFileModal from "./UploadStaticFileModal";

const StaticFilesIndex = () => {
  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [staticFiles, setStaticFiles] = useState([]);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [replaceFileModalOpen, setReplaceFileModalOpen] = useState(false);
  const [staticFileBeingReplaced, setStaticFileBeingReplaced] = useState(null);

  const [fetched, setFetched] = useState(false);
  const confirm = useConfirm();
  useEffect(() => {
    const fetchStaticFiles = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "contents/static_files"));
        setStaticFiles(result.data["static_files"]);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchStaticFiles();
  }, [apiRoot]);

  const humanFileSize = (bytes, dp = 1) => {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
  };

  const getStaticFileLink = (file) => {
    return urljoin(rootUrl, `/events/${event.gid}`, `/contents/static_files/${file.gid}`);
  };

  const columns = [
    {
      field: "original_filename",
      headerName: "Filename",
      flex: 1
    },
    {
      headerName: "Date Uploaded",
      field: "created_at",
      renderCell: (params) => formatTime(params.value, event.time_zone),
      flex: 1
    },
    {
      headerName: "File Size",
      field: "file_size",
      renderCell: (params) => humanFileSize(params.value, 1)
    },
    {
      field: "link",
      headerName: "Link",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigator.clipboard.writeText(getStaticFileLink(params.row));
              alertSuccess("URL copied to clipboard");
            }}
          >
            Copy Link
          </Link>
        );
      }
    },
    {
      field: "file_url",
      headerName: "Direct Link (Non Replacable)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigator.clipboard.writeText(params.row.file_url);
              alertSuccess("URL copied to clipboard");
            }}
          >
            Copy Direct Link
          </Link>
        );
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      getActions: (params) => [renderReplace(params.row), renderDelete(params.row)]
    }
  ];

  const renderReplace = (item) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              setReplaceFileModalOpen(true);
              setStaticFileBeingReplaced(item);
            }}
          >
            Replace
          </span>
        </>
      );
    }
    return <></>;
  };

  const renderDelete = (item) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              attemptDelete(item);
            }}
          >
            Delete
          </span>
        </>
      );
    }
    return <></>;
  };

  const attemptDelete = (item) => {
    confirm({
      title: "Confirm removal",
      description: "Are you sure you want to remove this static file? This cannot be undone."
    })
      .then(() => {
        performDelete(item);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const performDelete = (item) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/contents/static_files/${item.id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error == null) {
          setStaticFiles(staticFiles.filter((i) => i.id !== item.id));
          alertSuccess("Entry deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderStaticFilesTable = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <GrowlTable
        columns={columns}
        items={staticFiles}
        sortColumn="name"
        sortDirection="asc"
        tableName={`${event.slug}-static-files`}
      />
    );
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.content_static_files_edit) {
      return false;
    }
    return true;
  };

  const renderUploadFileButton = () => {
    if (editEnabled()) {
      return renderCreateButton("Upload Files", toggleUploadFileModal);
    }
  };

  const toggleUploadFileModal = () => {
    setUploadFileModalOpen(!uploadFileModalOpen);
  };

  const closeReplaceFileModal = () => {
    setReplaceFileModalOpen(false);
    setStaticFileBeingReplaced(null);
  };

  const renderUploadFileModal = () => {
    return (
      <UploadStaticFileModal
        modalVisible={uploadFileModalOpen}
        resetModal={toggleUploadFileModal}
        callbackFailure={() => {}}
        callbackSuccess={(files) => {
          setStaticFiles([...staticFiles, ...files]);
        }}
      />
    );
  };

  const renderReplaceFileModal = () => {
    return (
      <ReplaceStaticFileModal
        modalVisible={replaceFileModalOpen}
        resetModal={closeReplaceFileModal}
        staticFile={staticFileBeingReplaced}
        callbackFailure={() => {}}
        callbackSuccess={(file) => {
          console.log({
            file
          });
          setStaticFiles(staticFiles.filter((i) => i.id !== file.id).concat([file]));
        }}
      />
    );
  };

  return (
    <div>
      <PageHeader text="Static Files" />
      {renderUploadFileButton()}
      <div className="mt-8">
        {renderStaticFilesTable()}
        {renderUploadFileModal()}
        {renderReplaceFileModal()}
      </div>
    </div>
  );
};

export default StaticFilesIndex;
