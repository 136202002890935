import React, { useContext, useState } from "react";

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import { includes, lowerCase, orderBy } from "lodash";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import CheckFieldSmall from "@shared/CheckFieldSmall";
import CheckFieldSmallDisabled from "@shared/CheckFieldSmallDisabled";
import { renderTextField, renderCancelButton, renderSubmitButton, renderSelectField } from "@shared/FormUtils";
import { isDeveloper } from "@shared/UserUtils";

const UsersForm = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const {
    eventUsers,
    reset = () => {
      alertError("not implemented");
    },
    update = () => {
      alertError("not implemented");
    },
    user,
    users
  } = props;
  const { user: currentUser } = useContext(EventUserContext);
  const [showDeveloper, setShowDeveloper] = useState(false);
  const requireOnePermission = false;

  const isEdit = () => {
    return user && user.id;
  };

  const editEnabled = () => {
    if (currentUser.role === "basic" && !currentUser.permission.configuration_users_edit) {
      return false;
    }
    return true;
  };

  const toggleShowDeveloper = () => {
    setShowDeveloper(!showDeveloper);
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-user-edit",
        formUrl: urljoin(apiRoot, "/users", `/${user.id}`),
        method: "PATCH",
        title: "Edit User"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-user-add",
      formUrl: urljoin(apiRoot, "/users"),
      method: "POST",
      title: "Add User"
    };
  })();

  const rowStyle = "border-b w-full mb-2 flex text-sm";
  const nameStyle = "flex-none font-bold w-40 py-2";
  const permissionStyle = "flex-none w-24 py-2";

  const performReset = (id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/users", `/${id}`, "/reset"),
      method: "PATCH"
    }).then((response) => {
      if (response.data.error === null) {
        alertSuccess("Password reset sent");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const renderValue = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderVisibilityArrow = () => {
    if (showDeveloper) {
      return (
        <span key={Math.random()}>
          <FontAwesomeIcon icon={faCaretDown} />
          &nbsp;
        </span>
      );
    }
    return (
      <span key={Math.random()}>
        <FontAwesomeIcon icon={faCaretRight} />
        &nbsp;
      </span>
    );
  };

  const renderDeveloper = () => {
    if (isDeveloper(currentUser)) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2 className="cursor-pointer pl-1" onClick={toggleShowDeveloper}>
            {renderVisibilityArrow()}
            <span className="pl-1">Developer Details</span>
          </h2>
          {showDeveloper && renderDeveloperDetails()}
        </div>
      );
    }
    return <></>;
  };

  const renderDeveloperDetails = () => {
    return (
      <div>
        {renderValue("ID", user.id)}
        {renderValue("Current sign in datestamp", user.current_sign_in_at)}
        {renderValue("Current IP", user.current_ip)}
        {renderValue("Sign in count", user.sign_in_count)}
      </div>
    );
  };

  const configurationsDefaultValue = () => {
    return (
      user.permission.configuration_api_view ||
      user.permission.configuration_cache_view ||
      user.permission.configuration_event_view ||
      user.permission.configuration_features_view ||
      user.permission.configuration_custom_fields_view ||
      user.permission.configuration_users_view
    );
  };

  const formInitialValues = () => {
    return {
      name_last: user.name_last || "",
      name_first: user.name_first || "",
      job_title: user.job_title || "",
      company: user.company || "",
      email: user.email || "",
      id: user.id || "",
      permission: {
        communications_view: user.permission.communications_view || false,
        communications_edit: user.permission.communications_edit || false,
        communications_send: user.permission.communications_send || false,
        configurations: configurationsDefaultValue(),
        configuration_api_view: user.permission.configuration_api_view || false,
        configuration_api_edit: user.permission.configuration_api_edit || false,
        configuration_cache_view: user.permission.configuration_cache_view || false,
        configuration_event_view: user.permission.configuration_event_view || false,
        configuration_event_edit: user.permission.configuration_event_edit || false,
        configuration_features_view: user.permission.configuration_features_view || false,
        configuration_features_edit: user.permission.configuration_features_edit || false,
        configuration_users_view: user.permission.configuration_users_view || false,
        configuration_users_edit: user.permission.configuration_users_edit || false,
        configuration_users_delete: user.permission.configuration_users_delete || false,
        content_static_files_view: user.permission.content_static_files_view || false,
        content_static_files_edit: user.permission.content_static_files_edit || false,
        content_web_pages_view: user.permission.content_web_pages_view || false,
        content_web_pages_edit: user.permission.content_web_pages_edit || false,
        dashboard_view: user.permission.dashboard_view || true,
        dashboard_default_edit: user.permission.dashboard_default_edit || false,
        forms_view: user.permission.forms_view || false,
        forms_edit: user.permission.forms_edit || false,
        help_view: user.permission.help_view || false,
        help_edit: user.permission.help_edit || false,
        housing_view: user.permission.housing_view || false,
        housing_edit: user.permission.housing_edit || false,
        meetings_view: user.permission.meetings_view || false,
        meetings_edit: user.permission.meetings_edit || false,
        meetings_delete: user.permission.meetings_delete || false,
        participants_view: user.permission.participants_view || true,
        participants_edit: user.permission.participants_edit || false,
        participants_delete: user.permission.participants_delete || false,
        reports_view: user.permission.reports_view || false,
        reports_edit: user.permission.reports_edit || false,
        sessions_view: user.permission.sessions_view || false,
        sessions_edit: user.permission.sessions_edit || false,
        sessions_delete: user.permission.sessions_delete || false,
        speakers_view: user.permission.speakers_view || false,
        speakers_edit: user.permission.speakers_edit || false,
        ticketing_view: user.permission.ticketing_view || false,
        ticketing_edit: user.permission.ticketing_edit || false
      }
    };
  };

  const renderExistingUsers = (formatClasses = []) => {
    if (isEdit()) {
      return <></>;
    }
    const addedUserIds = eventUsers.map((u) => u.id);
    const filteredUsers = users
      .filter((u) => !includes(addedUserIds, u.id))
      .filter((x) => x.email.indexOf("@jaguardesignstudio.com") == -1);
    const userOptions = [{ label: "", value: "" }].concat(
      orderBy(filteredUsers, [(u) => lowerCase(u.name_last), (u) => lowerCase(u.name_first)], "asc").map((u) => ({
        label: `${u.name_last}, ${u.name_first} (${u.email})`,
        value: u.id
      }))
    );
    return (
      <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        {renderSelectField("", "user[id]", userOptions, [], [], false, { disabled: !editEnabled() })}
      </div>
    );
  };

  const renderPasswordReset = () => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <div className="my-4">
        <span
          className="sg-mgmt-link"
          onClick={() => {
            performReset(user.id);
          }}
        >
          Send Password Reset Email
        </span>
      </div>
    );
  };

  const renderAddUser = (userSelected) => {
    if (userSelected || !editEnabled()) {
      return <></>;
    }
    return (
      <>
        <div className="sg-mgmt-form-row">Or add new user:</div>
        <div className="sg-mgmt-form-row">
          {renderTextField("First Name", "user[name_first]", ["pr-3"], userSelected)}
          {renderTextField("Last Name", "user[name_last]", ["pl-3"], userSelected)}
        </div>
        <div className="sg-mgmt-form-row">{renderTextField("Company", "user[company]", [], userSelected)}</div>
        <div className="sg-mgmt-form-row">{renderTextField("Email Address", "user[email]", [], userSelected)}</div>
      </>
    );
  };

  const renderAddExistingUser = (id) => {
    if (!editEnabled()) {
      return <></>;
    }

    const userSelected = !!id;
    return (
      <>
        <div className="sg-mgmt-form-row">Add existing user to this site:</div>
        <div className="sg-mgmt-form-row">{renderExistingUsers()}</div>
        {renderAddUser(userSelected)}
      </>
    );
  };

  const renderUserDetails = () => {
    return (
      <>
        {renderValue("Name", `${user.name_first} ${user.name_last}`)}
        {renderValue("Email", user.email)}
      </>
    );
  };

  const renderUserInfo = (id) => {
    return (
      <div className="my-2">
        <h2>User Info</h2>
        <div className="my-2">
          {isEdit() && renderUserDetails()}
          {!isEdit() && renderAddExistingUser(id)}
          {isEdit() && renderPasswordReset()}
        </div>
      </div>
    );
  };

  const renderCheckDisabled = (label) => (
    <div className={clsx(permissionStyle)}>
      <CheckFieldSmallDisabled label={label} />
    </div>
  );

  const renderCheck = (fieldName, label) => {
    if (!editEnabled()) {
      return (
        <div className={clsx(permissionStyle)}>
          <CheckFieldSmallDisabled fieldName={fieldName} label={label} />
        </div>
      );
    }

    return (
      <div className={clsx(permissionStyle)}>
        <CheckFieldSmall fieldName={fieldName} label={label} />
      </div>
    );
  };

  const renderCheckDependent = (fieldName, label, value) => {
    if (value) {
      return renderCheck(fieldName, label);
    }
    return renderCheckDisabled(label);
  };

  const renderPermissionsCommunications = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Communications</div>
      {renderCheck("user[permission][communications_view]", "View")}
      {renderCheckDependent(
        "user[permission][communications_edit]",
        "Edit",
        values.user.permission.communications_view
      )}
      {renderCheckDependent(
        "user[permission][communications_send]",
        "Send",
        values.user.permission.communications_view
      )}
    </div>
  );

  const renderPermissionsDashboard = () => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Dashboard</div>
      {renderCheck("user[permission][dashboard_view]", "View")}
      {renderCheck("user[permission][dashboard_default_edit]", "Edit Main")}
    </div>
  );

  const renderPermissionsHousing = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Housing</div>
      {renderCheck("user[permission][housing_view]", "View")}
      {renderCheckDependent("user[permission][housing_edit]", "Edit", values.user.permission.housing_view)}
    </div>
  );

  const renderPermissionsForms = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Form Builder</div>
      {renderCheck("user[permission][forms_view]", "View")}
      {renderCheckDependent("user[permission][forms_edit]", "Edit", values.user.permission.forms_view)}
    </div>
  );

  const renderPermissionsContentStaticFiles = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>{"Content > Static Files"}</div>
      {renderCheck("user[permission][content_static_files_view]", "View")}
      {renderCheckDependent(
        "user[permission][content_static_files_edit]",
        "Edit",
        values.user.permission.content_static_files_view
      )}
    </div>
  );

  const renderPermissionsHelp = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>{"Help"}</div>
      {renderCheck("user[permission][help_view]", "View")}
    </div>
  );

  const renderPermissionsContentWebPages = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>{"Content > Web Pages"}</div>
      {renderCheck("user[permission][content_web_pages_view]", "View")}
      {renderCheckDependent(
        "user[permission][content_web_pages_edit]",
        "Edit",
        values.user.permission.content_web_pages_view
      )}
    </div>
  );

  const renderPermissionsMeetings = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Meetings</div>
      {renderCheck("user[permission][meetings_view]", "View")}
      {renderCheckDependent("user[permission][meetings_edit]", "Edit", values.user.permission.meetings_view)}
      {renderCheckDependent("user[permission][meetings_delete]", "Delete", values.user.permission.meetings_view)}
    </div>
  );

  const renderPermissionsParticipants = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Participants</div>
      {renderCheck("user[permission][participants_view]", "View")}
      {renderCheckDependent("user[permission][participants_edit]", "Edit", values.user.permission.participants_view)}
      {renderCheckDependent(
        "user[permission][participants_delete]",
        "Delete",
        values.user.permission.participants_view
      )}
    </div>
  );

  const renderPermissionsReports = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Reporting</div>
      {renderCheck("user[permission][reports_view]", "View")}
      {renderCheckDependent("user[permission][reports_edit]", "Edit", values.user.permission.reports_view)}
    </div>
  );

  const renderPermissionsSessions = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Sessions</div>
      {renderCheck("user[permission][sessions_view]", "View")}
      {renderCheckDependent("user[permission][sessions_edit]", "Edit", values.user.permission.sessions_view)}
      {renderCheckDependent("user[permission][sessions_delete]", "Delete", values.user.permission.sessions_edit)}
    </div>
  );

  const renderPermissionsTicketing = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Ticketing</div>
      {renderCheck("user[permission][ticketing_view]", "View")}
      {renderCheckDependent("user[permission][ticketing_edit]", "Edit", values.user.permission.ticketing_view)}
    </div>
  );

  const renderPermissionsConfigurations = () => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Configurations</div>
      {renderCheck("user[permission][configurations]", "View")}
    </div>
  );

  const renderPermissionsConfigurationApi = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>APIs</div>
      {renderCheck("user[permission][configuration_api_view]", "View")}
      {renderCheckDependent(
        "user[permission][configuration_api_edit]",
        "Edit",
        values.user.permission.configuration_api_view
      )}
    </div>
  );

  const renderPermissionsConfigurationCache = () => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Cache</div>
      {renderCheck("user[permission][configuration_cache_view]", "View")}
    </div>
  );

  const renderPermissionsConfigurationCustomFields = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Custom Fields</div>
      {renderCheck("user[permission][configuration_custom_fields_view]", "View")}
      {renderCheckDependent(
        "user[permission][configuration_custom_fields_edit]",
        "Edit",
        values.user.permission.configuration_custom_fields_view
      )}
    </div>
  );

  const renderPermissionsConfigurationEvent = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Event</div>
      {renderCheck("user[permission][configuration_event_view]", "View")}
      {renderCheckDependent(
        "user[permission][configuration_event_edit]",
        "Edit",
        values.user.permission.configuration_event_view
      )}
      {renderCheckDependent(
        "user[permission][configuration_event_delete]",
        "Delete",
        values.user.permission.configuration_event_edit
      )}
    </div>
  );

  const renderPermissionsConfigurationFeatures = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Features</div>
      {renderCheck("user[permission][configuration_features_view]", "View")}
      {renderCheckDependent(
        "user[permission][configuration_features_edit]",
        "Edit",
        values.user.permission.configuration_features_view
      )}
    </div>
  );

  const renderPermissionsConfigurationUsers = (values) => (
    <div className={clsx(rowStyle)}>
      <div className={clsx(nameStyle)}>Users</div>
      {renderCheck("user[permission][configuration_users_view]", "View")}
      {renderCheckDependent(
        "user[permission][configuration_users_edit]",
        "Edit",
        values.user.permission.configuration_users_view
      )}
      {renderCheckDependent(
        "user[permission][configuration_users_delete]",
        "Delete",
        values.user.permission.configuration_users_edit
      )}
    </div>
  );

  const renderConfigurationPermissions = (values) => {
    if (!values.user.permission.configurations) {
      return <></>;
    }

    return (
      <div>
        {renderPermissionsConfigurationEvent(values)}
        {renderPermissionsConfigurationCustomFields(values)}
        {renderPermissionsConfigurationFeatures(values)}
        {renderPermissionsConfigurationApi(values)}
        {renderPermissionsConfigurationCache()}
        {renderPermissionsConfigurationUsers(values)}
      </div>
    );
  };

  const renderPermissionSettings = (values) => (
    <div className={clsx("whitespace-nowrap")}>
      <h2>Permission Settings</h2>
      <div className="my-2 text-sm">
        {renderPermissionsDashboard()}
        {renderPermissionsParticipants(values)}
        {renderPermissionsCommunications(values)}
        {renderPermissionsSessions(values)}
        {renderPermissionsForms(values)}
        {renderPermissionsHousing(values)}
        {renderPermissionsMeetings(values)}
        {renderPermissionsTicketing(values)}
        {renderPermissionsReports(values)}
        {renderPermissionsContentStaticFiles(values)}
        {renderPermissionsContentWebPages(values)}
        {renderPermissionsConfigurations()}
        {renderConfigurationPermissions(values)}
        {renderPermissionsHelp(values)}
      </div>
    </div>
  );

  const validate = (values) => {
    const errors = {};

    if (!values.user.email && !values.user.id) {
      const msg = "Email is required";
      alertError(msg);
      errors["user[email]"] = msg;
    }

    if (values.user.email.indexOf("@jaguardesignstudio") != -1) {
      const msg = "Jaguar Design Studio Employees have access to all events, do not need to be added.";
      alertError(msg);
      errors["user[email]"] = msg;
    }

    if (requireOnePermission) {
      if (!Object.values(values.user.permission).some((val) => val == true)) {
        const msg = "At least one permission is required";
        alertError(msg);
        errors["user[permission]"] = msg;
      }
    }

    return errors;
  };

  const renderErrors = (errors) => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      return keys.map((x) => {
        const error = errors[x];
        return (
          <div key={x} className="font-bold text-red-500">
            <p>{error}</p>
          </div>
        );
      });
    }
  };

  const renderAddEditUser = () => {
    return (
      <Formik
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          user: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

          Object.keys(values.user.permission).map((perm) => {
            formData.append(`user[permission][${perm}]`, values.user.permission[perm]);
          });

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then((response) => {
              if (response.data.error === null) {
                update(response.data.user);
                reset();
                alertSuccess(`User ${formConfig.alert} successfully`);
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {({ values, isSubmitting, errors }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderUserInfo(values.user.id)}
            {renderPermissionSettings(values)}
            {isEdit() && renderDeveloper()}
            {renderErrors(errors)}
            <Stack direction="row" spacing={2}>
              {editEnabled() ? renderSubmitButton("Save", isSubmitting) : ""}
              {renderCancelButton("Cancel", reset)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderAddEditUser()}</div>;
};

export default UsersForm;
